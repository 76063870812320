// =============================================================================
// MIXES-DATA.JS
// -----------------------------------------------------------------------------
// Names, songs, and sets.
// =============================================================================

// =============================================================================
// TABLE OF CONTENTS
// -----------------------------------------------------------------------------
//   01. Names
//   02. Songs
//   03. Mixes
// =============================================================================

// Names
// =============================================================================

const nameAlexJ         = "Alex Johnson"
const nameAriR          = "Ariana Reyes"
const nameBeccahR       = "Beccah Rainey"
const nameBrianO        = "Brian Oliver"
const nameCadenW        = "Caden Watkins"
const nameCalebH        = "Caleb Hinojosa"
const nameCameronO      = "Cameron Owens"
const nameCassieC       = "Cassie Chisum"
const nameCharlieB      = "Charlie Basurto"
const nameCoaloZ        = "Coalo Zamorano"
const nameCollinS       = "Collin Smith"
const nameCountdown     = "Countdown"
const nameDanielS       = "Daniel Starnes"
const nameEliotJ        = "Eliot Johnson"
const nameEricaS        = "Erica Skinner"
const nameEzrieK        = "Ezrie Katzen"
const nameEmmaB         = "Emma Bayne"
const nameEmmaD         = "Emma Derrick"
const nameEmmyH         = "Emmy Hodges"
const nameEnsemble      = "Ensemble"
const nameGailC         = "Gail Chiofalo"
const nameGeniB         = "Geni Basurto"
const nameHallieW       = "Hallie Ware"
const nameJamesB        = "James Barfield"
const nameJaredL        = "Jared Lyda"
const nameJasonC        = "Jason Clark"
const nameJasonS        = "Jason Snyder"
const nameJeffM         = "Jeff McIntosh"
const nameJessieH       = "Jessie Harris"
const nameJofeeT        = "Jofee' Tremain"
const nameJordanP       = "Jordan Proffer"
const nameJoshM         = "Josh Menefee"
const nameJustinM       = "Justin McCormick"
const nameKelleyD       = "Kelley Daniel"
const nameKevinA        = "Kevin Arellano"
const nameKyleG         = "Kyle Gray"
const nameLudoB         = "Ludo Baccherini"
const nameMacyW         = "Macy Walker"
const nameMartyS        = "Marty Skinner"
const nameMatthewH      = "Matthew Harris"
// const nameMichaelC      = "Michael Cooper"
const nameMicahS        = "Micah Schenck"
const nameMikeyC        = "Mikey Chiofalo"
const nameMorganL       = "Morgan Lyda"
const nameNatalieS      = "Natalie Sandifer"
const nameNickC         = "Nick Chiofalo"
const namePaulW         = "Paul Wilkinson"
const nameRyanB         = "Ryan Bradetich"
const nameSamD          = "Sam Downe"
const nameShannonC      = "Shannon Crowley"
const nameSilasB        = "Silas Bell"
const nameTylerC        = "Tyler Coffee"
const nameWhitneyC      = "Whitney Clark"
const nameZachWoolhouse = "Zach Woolhouse"
const nameBand          = "Band"



// Songs
// =============================================================================

const song10000Reasons               = "10,000 Reasons"
const songAbide                      = "Abide"
const songAlive                      = "Alive"
const songAllHailKingJesus           = "All Hail King Jesus"
const songAllThingsTogether          = "All Things Together"
const songAmazingGrace               = "Amazing Grace"
const songAncientGates               = "Ancient Gates"
const songAmazingLove                = "Ancient Love"
const songAngelsWeHaveHeardOnHigh    = "Angels We Have Heard On High"
const songAnotherInTheFire           = "Another In the Fire"
const songAThousandHallelujahs       = "A Thousand Hallelujahs"
const songAwakeMySoul                = "Awake My Soul"
const songAwayInAManger              = "Away In a Manger"
const songBattleBelongs              = "Battle Belongs"
const songBeautifulJesus             = "Beautiful Jesus"
const songBeGlad                     = "Be Glad"
const songBeNear                     = "Be Near"
const songBlessedAssurance           = "Blessed Assurance"
const songBreathe                    = "Breathe"
const songBuildMyLife                = "Build My Life"
const songChampion                   = "Champion"
const songChristBeMagnified          = "Christ Be Magnified"
const songClean                      = "Clean"
const songComeAlive                  = "Come Alive"
const songComeAllYeFaithful          = "Come All Ye Faithful"
const songComeThouFount              = "Come Thou Fount"
const songComeWhatMay                = "Come What May"
const songCornerstone                = "Cornerstone"
const songCoverTheEarth              = "Cover the Earth"
const songDeathWasArrested           = "Death Was Arrested"
const songDesertSong                 = "Desert Song"
const songDoItAgain                  = "Do It Again"
const songDoxology                   = "Doxology"
const songDrawMeClose                = "Draw Me Close"
const songDryBones                   = "Dry Bones"
const songEgypt                      = "Egypt"
const songEverlastingGod             = "Everlasting God"
const songFaithAndWonder             = "Faith and Wonder"
const songFallAfresh                 = "Fall Afresh"
const songFamousOne                  = "Famous One"
const songForeverReign               = "Forever Reign"
const songForeverYours               = "Forever Yours"
const songFirmFoundation             = "Firm Foundation"
const songFromTheInsideOut           = "From the Inside Out"
const songGiveMeFaith                = "Give Me Faith"
const songGloria                     = "Gloria"
const songGloriousDay                = "Glorious Day"
const songGloryToGlory               = "Glory To Glory"
const songGodIsAble                  = "God is Able"
const songGodSoLoved                 = "God So Loved"
const songGoodbyeYesterday           = "Goodbye Yesterday"
const songGoodGoodFather             = "Good Good Father"
const songGoodnessOfGod              = "Goodness of God"
const songGoodnessOfTheLord          = "Goodness of the Lord"
const songGoodPlans                  = "Good Plans"
const songGoTellItOnTheMountain      = "Go Tell It On the Mountain"
const songGratitude                  = "Gratitude"
const songGravesIntoGardens          = "Graves Into Gardens"
const songGreatAreYouLord            = "Great Are You, Lord"
const songGreaterThan                = "Greater Than"
const songGreatIsThyFaithfulness     = "Great is Thy Faithfulness"
const songGreatThings                = "Great Things"
const songHarkTheHerald              = "Hark the Herald"
const songHarkTheHeraldAngelsSing    = "Hark! The Herald Angels Sing"
const songHaveMyHeart                = "Have My Heart"
const songHeIsFaithful               = "He Is Faithful"
const songHereAgain                  = "Here Again"
const songHereForYou                 = "Here for You"
const songHereIAmToWorship           = "Here I Am to Worship"
const songHereInYourPresence         = "Here In Your Presence"
const songHeresMyHeart               = "Here's My Heart"
const songHisEyeIsOnTheSparrow       = "His Eye Is on the Sparrow"
const songHisNameIsJesus             = "His Name is Jesus"
const songHolyForever                = "Holy Forever"
const songHolyHolyHoly               = "Holy Holy Holy"
const songHosanna                    = "Hosanna"
const songHowGreatIsOurGod           = "How Great Is Our God"
const songHowGreatThouArt            = "How Great Thou Art"
const songHowMarvelous               = "How Marvelous"
const songHouseOfTheLord             = "House of the Lord"
const songHowDeepTheFathersLove      = "How Deep The Father's Love"
const songInChristAlone              = "In Christ Alone"
const songIndescribable              = "Indescribable"
const songINeedYou                   = "I Need You"
const songInstrumental               = "Instrumental"
const songInterlude                  = "Interlude"
const songInTheHandsOfChristMyKing   = "In The Hands of Christ My King"
const songInTheRoom                  = "In The Room"
const songIntro                      = "Intro"
const songISpeakJesus                = "I Speak Jesus"
const songIStandInAwe                = "I Stand in Awe"
const songISurrender                 = "I Surrender"
const songItCameUponAMidnightClear   = "It Came Upon a Midnight Clear"
const songItIsWell                   = "It is Well"
const songITrustInGod                = "I Trust in God"
const songIveWitnessedIt             = "I've Witnessed It"
const songJehovah                    = "Jehovah"
const songJesusOnlyJesus             = "Jesus Only Jesus"
const songJesusPaidItAll             = "Jesus Paid It All"
const songJesusYouAlone              = "Jesus You Alone"
const songJoyToTheWorld              = "Joy to the World"
const songLeadMeToTheCross           = "Lead Me to the Cross"
const songLivingHope                 = "Living Hope"
const songLordINeedYou               = "Lord, I Need You"
const songLoveShines                 = "Love Shines"
const songMakeRoom                   = "Make Room"
const songMightyRedeemer             = "Mighty Redeemer"
const songMyChainsAreGone            = "My Chains Are Gone"
const songNeverLost                  = "Never Lost"
const songMonologue                  = "Monologue"
const songMoreLikeJesus              = "More Like Jesus"
const songMoreThanAble               = "More Than Able"
const songMoreThanAnything           = "More Than Anything"
const songMuzak                      = "Muzak"
const songMyKingForever              = "My King Forever"
const songNoBody                     = "No Body"
const songNoOtherName                = "No Other Name"
const songNoLongerSlaves             = "No Longer Slaves"
const songNothingButTheBlood         = "Nothing But the Blood"
const songNothingElse                = "Nothing Else"
const songNothingIsHoldingMeBack     = "Nothing Is Holding Me Back"
const songNotInAHurry                = "Not in a Hurry"
const songOComeAllYeFaithful         = "O Come All Ye Faithful"
const songOComeOComeEmmanuel         = "O Come O Come Emmanuel"
const songOComeToTheAltar            = "O Come to the Altar"
const songOForAThousandTonguesToSing = "O, For A Thousand Tongues to Sing"
const songOHolyNight                 = "O Holy Night"
const songOpenTheEyes                = "Open the Eyes"
const songOpenTheHeavens             = "Open the Heavens"
const songOPraiseTheName             = "O Praise the Name"
const songOceans                     = "Oceans"
const songOhHowINeedYou              = "Oh, How I Need You"
const songOverAndOver                = "Over and Over"
const songPraise                     = "Praise"
const songPraiseYourName             = "Praise Your Name"
const songPsalm23                    = "Psalm 23"
const songPsalm46                    = "Psalm 46"
const songQuiet                      = "Quiet"
const songRaiseAHallelujah           = "Raise A Hallelujah"
const songRattle                     = "Rattle"
const songReasonISing                = "Reason I Sing"
const songRecklessLove               = "Reckless Love"
const songRefiner                    = "Refiner"
const songReignAboveItAll            = "Reign Above it All"
const songResurrender                = "Resurrender"
const songRevelationSong             = "Revelation Song"
const songRiver                      = "River"
const songRooftops                   = "Rooftops"
const songRunToTheFather             = "Run to the Father"
const songSalvationIsHere            = "Salvation Is Here"
const songSameGod                    = "Same God"
const songSeptember                  = "September"
const songSetAFire                   = "Set A Fire"
const songSilentNight                = "Silent Night"
const songSomethingGood              = "Something Good"
const songSoulWillSing               = "Soul Will Sing"
const songSundayIsComing             = "Sunday is Coming"
const songSurrounded                 = "Surrounded"
const songTakeItAll                  = "Take It All"
const songTakeYouAtYourWord          = "Take You at Your Word"
const songTellMeTheStoryOfJesus      = "Tell Me the Story of Jesus"
const songTheBlessing                = "The Blessing"
const songTheBlood                   = "The Blood"
const songTheFirstNoel               = "The First Noel"
const songTheGoodnessOfJesus         = "The Goodness of Jesus"
const songTheLionAndTheLamb          = "The Lion and the Lamb"
const songTheLordWillProvide         = "The Lord Will Provide"
const songTheStand                   = "The Stand"
const songTheWay                     = "The Way"
const songThisIBelieve               = "This I Believe"
const songThisIsAmazingGrace         = "This Is Amazing Grace"
const songThisIsOurGod               = "This Is Our God"
const songThisLittleLight            = "This Little Light"
const songThisSideOfHeaven           = "This Side of Heaven"
const songThisWeKnow                 = "This We Know"
const songTouchOfHeaven              = "Touch of Heaven"
const songThreeInOne                 = "Three In One"
const songTremble                    = "Tremble"
const songTurnYourEyesUponJesus      = "Turn Your Eyes Upon Jesus"
const songUndividedHeart             = "Undivided Heart"
const songUnstoppableGod             = "Unstoppable God"
const songWayMaker                   = "Way Maker"
const songWePraiseYou                = "We Praise You"
const songWeWelcomeYouWithPraise     = "We Welcome You With Praise"
const songWhatABeautifulName         = "What A Beautiful Name"
const songWhatHesDone                = "What He's Done"
const songWhatTheWorldWillNeverTake  = "What the World Will Never Take"
const songWhoIsLikeTheLord           = "Who is Like the Lord"
const songWholeHeart                 = "Whole Heart"
const songWontStopNow                = "Won't Stop Now"
const songWorldNeedsJesus            = "World Needs Jesus"
const songWorthy                     = "Worthy"
const songWorthyOfItAll              = "Worthy of it All"
const songWorthyOfYourName           = "Worthy of Your Name"
const songWorthyWorthy               = "Worthy Worthy"
const songYesIWill                   = "Yes I Will"
const songYouCame                    = "You Came"
const songYouMakeMeBrave             = "You Make Me Brave"
const songYouNeverLetGo              = "You Never Let Go"
const songYouveAlreadyWon            = "You've Already Won"



// Mixes
// =============================================================================

export const theMixes = [
  {
    date    : "October 2, 2024",
    day     : "Wednesday",
    events  : ["Echo"],
    slug    : "2024-10-02",
    remix   : false,
    setlist : [
      { start : "0:00", title : songTakeYouAtYourWord, singer : nameMikeyC   },
      { start : "3:52", title : songTheBlood,          singer : nameMikeyC   },
      { start : "8:35", title : songUndividedHeart,    singer : nameNatalieS },
    ],
    band : [
      { position : "d", player : nameJordanP  },
      { position : "b", player : nameJoshM    },
      { position : "e", player : nameMicahS   },
      { position : "e", player : nameKevinA   },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameMikeyC   },
    ],
  },
  {
    date    : "September 29, 2024",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2024-09-29",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songYouveAlreadyWon, singer : nameHallieW },
      { start : "6:12",  title : songRevelationSong,  singer : nameEmmaB   },
      { start : "10:51", title : songLivingHope,      singer : nameMikeyC  },
    ],
    band : [
      { position : "d", player : nameEliotJ   },
      { position : "b", player : nameMartyS   },
      { position : "e", player : nameJustinM  },
      { position : "e", player : namePaulW    },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameMikeyC   },
    ],
  },
  {
    date    : "September 25, 2024",
    day     : "Wednesday",
    events  : ["Echo"],
    slug    : "2024-09-25",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songGoodbyeYesterday, singer : nameMikeyC  },
      { start : "5:03",  title : songYouMakeMeBrave,   singer : nameHallieW },
      { start : "10:18", title : songFirmFoundation,   singer : nameMikeyC  },
      { start : "16:50", title : songGratitude,        singer : nameMikeyC  },
    ],
    band : [
      { position : "d", player : nameJordanP  },
      { position : "b", player : nameRyanB    },
      { position : "e", player : nameNickC    },
      { position : "e", player : nameMicahS   },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameMikeyC   },
    ],
  },
  {
    date    : "September 22, 2024",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2024-09-22",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songGreatIsThyFaithfulness, singer : nameEmmaB  },
      { start : "1:34",  title : songSameGod,                singer : nameMikeyC },
      { start : "7:01",  title : songTheLordWillProvide,     singer : nameEmmaB  },
      { start : "12:26", title : songAllHailKingJesus,       singer : nameGeniB  },
    ],
    band : [
      { position : "d", player : nameEliotJ   },
      { position : "b", player : nameAlexJ    },
      { position : "e", player : nameJustinM  },
      { position : "e", player : namePaulW    },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameMikeyC   },
    ],
  },
  {
    date    : "September 18, 2024",
    day     : "Wednesday",
    events  : ["Echo"],
    slug    : "2024-09-18",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songYouveAlreadyWon, singer : nameHallieW },
      { start : "6:01",  title : songHosanna,         singer : nameEmmaB   },
      { start : "11:32", title : songCoverTheEarth,   singer : nameEmmaB   },
      { start : "15:18", title : songGoodnessOfGod,   singer : nameMikeyC  },
    ],
    band : [
      { position : "d", player : nameJordanP  },
      { position : "b", player : nameJoshM    },
      { position : "e", player : nameNickC    },
      { position : "e", player : nameMicahS   },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameMikeyC   },
    ],
  },
  {
    date    : "September 11, 2024",
    day     : "Wednesday",
    events  : ["Echo"],
    slug    : "2024-09-11",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songPraise,      singer : nameMikeyC  },
      { start : "3:51",  title : songComeWhatMay, singer : nameMikeyC  },
      { start : "7:18",  title : songISpeakJesus, singer : nameBeccahR },
      { start : "13:11", title : songRefiner,     singer : nameMikeyC  },
    ],
    band : [
      { position : "d", player : nameJordanP  },
      { position : "b", player : nameJoshM    },
      { position : "e", player : nameMicahS   },
      { position : "e", player : namePaulW    },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameMikeyC   },
    ],
  },
  {
    date    : "September 8, 2024",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2024-09-08",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songPraise,           singer : nameGailC },
      { start : "3:30",  title : songThreeInOne,       singer : nameEmmaB },
      { start : "9:12",  title : songHolyForever,      singer : nameGeniB },
      { start : "13:54", title : songAllHailKingJesus, singer : nameGeniB },
    ],
    band : [
      { position : "d", player : nameMikeyC   },
      { position : "b", player : nameAlexJ    },
      { position : "e", player : nameJasonC   },
      { position : "e", player : nameJustinM  },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameCameronO },
    ],
  },
  {
    date    : "September 4, 2024",
    day     : "Wednesday",
    events  : ["Echo"],
    slug    : "2024-09-04",
    remix   : false,
    setlist : [
      { start : "0:00", title : songWontStopNow,    singer : nameMikeyC },
      { start : "4:06", title : songWePraiseYou,    singer : nameEmmyH  },
      { start : "8:21", title : songUndividedHeart, singer : nameMikeyC },
    ],
    band : [
      { position : "d", player : nameJordanP  },
      { position : "b", player : nameJoshM    },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : namePaulW    },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameMikeyC   },
    ],
  },
  {
    date    : "August 28, 2024",
    day     : "Wednesday",
    events  : ["Echo"],
    slug    : "2024-08-28",
    remix   : false,
    setlist : [
      { start : "0:00", title : songLoveShines,  singer : nameMikeyC  },
      { start : "5:01", title : songHolyForever, singer : nameHallieW },
    ],
    band : [
      { position : "d", player : nameJordanP  },
      { position : "b", player : nameSilasB   },
      { position : "e", player : nameJasonC   },
      { position : "e", player : nameMikeyC   },
      { position : "k", player : nameShannonC },
    ],
  },
  {
    date    : "August 25, 2024",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2024-08-25",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songBattleBelongs,         singer : nameHallieW },
      { start : "4:26",  title : songSameGod,               singer : nameSamD    },
      { start : "10:25", title : songTurnYourEyesUponJesus, singer : nameSamD    },
      { start : "11:43", title : songGoodnessOfGod,         singer : nameSamD    },
    ],
    band : [
      { position : "d", player : nameMikeyC   },
      { position : "b", player : nameAlexJ    },
      { position : "e", player : nameJasonC   },
      { position : "e", player : nameJustinM  },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameSamD     },
    ],
  },
  {
    date    : "August 18, 2024",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2024-08-18",
    remix   : false,
    setlist : [
      { start : "0:00", title : songSurrounded,  singer : nameEmmaB   },
      { start : "5:11", title : songPsalm46,     singer : nameMikeyC  },
      { start : "9:53", title : songISpeakJesus, singer : nameHallieW },
    ],
    band : [
      { position : "d", player : nameEliotJ   },
      { position : "b", player : nameMartyS   },
      { position : "e", player : nameMikeyC   },
      { position : "e", player : nameJustinM  },
      { position : "k", player : nameBeccahR  },
      { position : "k", player : nameShannonC },
    ],
  },
  {
    date    : "August 11, 2024",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2024-08-11",
    remix   : false,
    setlist : [
      { start : "0:00", title : songHisNameIsJesus,   singer : nameBeccahR },
      { start : "1:34", title : songGiveMeFaith,      singer : nameHallieW },
      { start : "5:38", title : songLeadMeToTheCross, singer : nameEmmaB   },
      { start : "9:53", title : songNotInAHurry,      singer : nameMikeyC  },
    ],
    band : [
      { position : "d", player : nameJasonS   },
      { position : "b", player : nameAlexJ    },
      { position : "e", player : nameJasonC   },
      { position : "e", player : namePaulW    },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameMikeyC   },
    ],
  },
  {
    date    : "August 4, 2024",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2024-08-04",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songThisWeKnow,        singer : nameHallieW },
      { start : "4:21",  title : songComeThouFount,     singer : nameJasonC  },
      { start : "7:06",  title : songNothingElse,       singer : nameBeccahR },
      { start : "11:01", title : songChristBeMagnified, singer : nameJasonC  },
    ],
    band : [
      { position : "d", player : nameEliotJ   },
      { position : "b", player : nameMartyS   },
      { position : "e", player : nameJustinM  },
      { position : "e", player : namePaulW    },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameJasonC   },
    ],
  },
  {
    date    : "July 21, 2024",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2024-07-21",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songWePraiseYou,   singer : nameCassieC },
      { start : "4:10",  title : songGloryToGlory,  singer : nameDanielS },
      { start : "9:09",  title : songCoverTheEarth, singer : nameCassieC },
      { start : "13:05", title : songInTheRoom,     singer : nameDanielS },
    ],
    band : [
      { position : "d", player : nameLudoB    },
      { position : "b", player : nameJamesB   },
      { position : "e", player : nameJustinM  },
      { position : "e", player : namePaulW    },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameDanielS  },
    ],
  },
  {
    date    : "July 14, 2024",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2024-07-14",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songBattleBelongs,  singer : nameHallieW },
      { start : "4:23",  title : songUndividedHeart, singer : nameMikeyC  },
      { start : "8:57",  title : songISpeakJesus,    singer : nameGeniB   },
      { start : "13:49", title : songRefiner,        singer : nameEmmaB   },
    ],
    band : [
      { position : "d", player : nameEliotJ   },
      { position : "b", player : nameAlexJ    },
      { position : "e", player : nameJasonC   },
      { position : "e", player : nameJustinM  },
      { position : "k", player : nameCharlieB },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameMikeyC   },
    ],
  },
  {
    date    : "July 7, 2024",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2024-07-07",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songReasonISing,  singer : nameGeniB   },
      { start : "4:01",  title : songAmazingLove,  singer : nameMikeyC  },
      { start : "5:34",  title : songMoreThanAble, singer : nameHallieW },
      { start : "10:41", title : songInTheRoom,    singer : nameHallieW },
    ],
    band : [
      { position : "d", player : nameLudoB    },
      { position : "b", player : nameAlexJ    },
      { position : "e", player : nameMikeyC   },
      { position : "e", player : namePaulW    },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameHallieW  },
    ],
  },
  {
    date    : "June 16, 2024",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2024-06-16",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songWePraiseYou,        singer : nameHallieW },
      { start : "4:21",  title : songLivingHope,         singer : nameMikeyC  },
      { start : "10:21", title : songSameGod,            singer : nameGailC   },
      { start : "16:01", title : songWhatABeautifulName, singer : nameAriR    },
    ],
    band : [
      { position : "d", player : nameEliotJ   },
      { position : "b", player : nameAlexJ    },
      { position : "e", player : nameJasonC   },
      { position : "e", player : nameJustinM  },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameMikeyC   },
    ],
  },
  {
    date    : "June 9, 2024",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2024-06-09",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songReasonISing,     singer : nameCharlieB },
      { start : "4:46",  title : songBuildMyLife,     singer : nameHallieW  },
      { start : "10:37", title : songFallAfresh,      singer : nameGeniB    },
      { start : "15:55", title : songGreatAreYouLord, singer : nameGeniB    },
    ],
    band : [
      { position : "d", player : nameEliotJ   },
      { position : "b", player : nameAlexJ    },
      { position : "e", player : nameJasonC   },
      { position : "e", player : namePaulW    },
      { position : "k", player : nameCharlieB },
      { position : "a", player : nameCameronO },
    ],
  },
  {
    date    : "June 2, 2024",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2024-06-02",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songYouveAlreadyWon,  singer : nameMikeyC },
      { start : "5:56",  title : songComeThouFount,    singer : nameMikeyC },
      { start : "8:50",  title : songAbide,            singer : nameGailC  },
      { start : "13:35", title : songAllHailKingJesus, singer : nameGeniB  },
    ],
    band : [
      { position : "d", player : nameEliotJ   },
      { position : "b", player : nameMartyS   },
      { position : "e", player : nameMikeyC   },
      { position : "e", player : namePaulW    },
      { position : "k", player : nameShannonC },
    ],
  },
  {
    date    : "May 22, 2024",
    day     : "Wednesday",
    events  : ["Echo"],
    slug    : "2024-05-22",
    remix   : false,
    setlist : [
      { start : "0:00", title : songUndividedHeart,    singer : nameMikeyC },
      { start : "4:52", title : songAllThingsTogether, singer : nameMikeyC },
    ],
    band : [
      { position : "d", player : nameJordanP },
      { position : "b", player : nameJoshM   },
      { position : "e", player : nameEzrieK  },
      { position : "e", player : nameMikeyC  },
      { position : "k", player : nameBeccahR },
    ],
  },
  {
    date    : "May 19, 2024",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2024-05-19",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songReasonISing,        singer : nameMikeyC  },
      { start : "4:19",  title : songHowMarvelous,       singer : nameBeccahR },
      { start : "5:25",  title : songHolyForever,        singer : nameGeniB   },
      { start : "10:01", title : songOComeToTheAltar,    singer : nameMikeyC  },
      { start : "13:46", title : songHereInYourPresence, singer : nameMikeyC  },
    ],
    band : [
      { position : "d", player : nameJasonS   },
      { position : "b", player : nameAlexJ    },
      { position : "e", player : nameJasonC   },
      { position : "e", player : nameJustinM  },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameMikeyC   },
    ],
  },
  {
    date    : "May 15, 2024",
    day     : "Wednesday",
    events  : ["Echo"],
    slug    : "2024-05-15",
    remix   : false,
    setlist : [
      { start : "0:00", title : songReasonISing,      singer : nameMikeyC  },
      { start : "4:22", title : songHolyForever,      singer : nameHallieW },
      { start : "9:01", title : songAllHailKingJesus, singer : nameHallieW },
    ],
    band : [
      { position : "d", player : nameJordanP  },
      { position : "b", player : nameSilasB   },
      { position : "e", player : nameMikeyC   },
      { position : "e", player : namePaulW    },
      { position : "k", player : nameShannonC },
    ],
  },
  {
    date    : "May 12, 2024",
    day     : "Sunday",
    events  : ["Main", "Mother's Day"],
    slug    : "2024-05-12",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songWePraiseYou,    singer : nameHallieW },
      { start : "4:34",  title : songBeautifulJesus, singer : nameEmmaB   },
      { start : "9:23",  title : songISpeakJesus,    singer : nameGeniB   },
      { start : "14:01", title : songGoodPlans,      singer : nameGailC   },
    ],
    band : [
      { position : "d", player : nameMikeyC   },
      { position : "b", player : nameMartyS   },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameJasonC   },
      { position : "k", player : nameCalebH   },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameHallieW  },
    ],
  },
  {
    date    : "May 8, 2024",
    day     : "Wednesday",
    events  : ["Echo"],
    slug    : "2024-05-08",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songWontStopNow,   singer : nameMikeyC },
      { start : "4:24",  title : songGoodPlans,     singer : nameMikeyC },
      { start : "9:15",  title : songCoverTheEarth, singer : nameEmmaB  },
      { start : "13:11", title : songGratitude,     singer : nameEmmaB  },
    ],
    band : [
      { position : "d", player : nameJordanP  },
      { position : "b", player : nameCalebH   },
      { position : "e", player : nameMikeyC   },
      { position : "e", player : nameEzrieK   },
      { position : "k", player : nameShannonC },
    ],
  },
  {
    date    : "May 5, 2024",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2024-05-05",
    remix   : false,
    setlist : [
      { start : "0:00", title : songPraise,       singer : nameCharlieB },
      { start : "3:31", title : song10000Reasons, singer : nameCharlieB },
      { start : "4:49", title : songWorthy,       singer : nameBeccahR  },
      { start : "9:43", title : songHolyForever,  singer : nameCassieC  },
    ],
    band : [
      { position : "d", player : nameEliotJ   },
      { position : "b", player : nameAlexJ    },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : namePaulW    },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameCameronO },
    ],
  },
  {
    date    : "May 1, 2024",
    day     : "Wednesday",
    events  : ["Echo"],
    slug    : "2024-05-01",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songYouveAlreadyWon,  singer : nameMikeyC  },
      { start : "6:13",  title : songUndividedHeart,   singer : nameMikeyC  },
      { start : "10:52", title : songAllHailKingJesus, singer : nameBeccahR },
    ],
    band : [
      { position : "d", player : nameJordanP  },
      { position : "b", player : nameEzrieK   },
      { position : "e", player : nameMikeyC   },
      { position : "e", player : nameSilasB   },
      { position : "k", player : nameShannonC },
    ],
  },
  {
    date    : "April 21, 2024",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2024-04-21",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songAncientGates,     singer : nameBeccahR },
      { start : "4:33",  title : songRevelationSong,   singer : nameEmmaB   },
      { start : "9:14",  title : songLivingHope,       singer : nameGailC   },
      { start : "10:39", title : songAllHailKingJesus, singer : nameGeniB   },
      { start : "15:40", title : songHereIAmToWorship, singer : nameEmmaB   },
    ],
    band : [
      { position : "d", player : nameMikeyC   },
      { position : "b", player : nameAlexJ    },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : namePaulW    },
      { position : "k", player : nameShannonC },
    ],
  },
  {
    date    : "April 14, 2024",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2024-04-14",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songBattleBelongs,    singer : nameHallieW },
      { start : "4:32",  title : songITrustInGod,      singer : nameGailC   },
      { start : "10:19", title : songIStandInAwe,      singer : nameJasonC  },
      { start : "11:39", title : songAllHailKingJesus, singer : nameGeniB   },
    ],
    band : [
      { position : "d", player : nameMikeyC   },
      { position : "b", player : nameAlexJ    },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : namePaulW    },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameJasonC   },
    ],
  },
  {
    date    : "April 10, 2024",
    day     : "Wednesday",
    events  : ["Echo"],
    slug    : "2024-04-10",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songYouMakeMeBrave, singer : nameHallieW },
      { start : "5:13",  title : songMoreThanAble,   singer : nameMikeyC  },
      { start : "10:56", title : songGoodPlans,      singer : nameHallieW },
    ],
    band : [
      { position : "d", player : nameJordanP  },
      { position : "b", player : nameEzrieK   },
      { position : "e", player : nameMikeyC   },
      { position : "e", player : namePaulW    },
      { position : "k", player : nameShannonC },
    ],
  },
  {
    date    : "March 31, 2024",
    day     : "Sunday",
    events  : ["Main", "Easter"],
    slug    : "2024-03-31",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songNoBody,             singer : nameKyleG   },
      { start : "1:40",  title : songRattle,             singer : nameGailC   },
      { start : "5:29",  title : songYouveAlreadyWon,    singer : nameHallieW },
      { start : "11:54", title : songMyChainsAreGone,    singer : nameGailC   },
      { start : "13:35", title : songHolyForever,        singer : nameGeniB   },
      { start : "18:40", title : songWhatABeautifulName, singer : nameEmmaB   },
    ],
    band : [
      { position : "d", player : nameMikeyC   },
      { position : "b", player : nameAlexJ    },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameJasonC   },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameHallieW  },
    ],
  },
  {
    date    : "March 27, 2024",
    day     : "Wednesday",
    events  : ["Echo"],
    slug    : "2024-03-27",
    remix   : false,
    setlist : [
      { start : "0:00", title : songWontStopNow,      singer : nameMikeyC  },
      { start : "4:34", title : songThisSideOfHeaven, singer : nameHallieW },
      { start : "9:11", title : songGratitude,        singer : nameMikeyC  },
    ],
    band : [
      { position : "d", player : nameJordanP  },
      { position : "b", player : nameEzrieK   },
      { position : "e", player : nameNickC    },
      { position : "e", player : namePaulW    },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameMikeyC   },
    ],
  },
  {
    date    : "March 20, 2024",
    day     : "Wednesday",
    events  : ["Echo"],
    slug    : "2024-03-20",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songTakeYouAtYourWord,  singer : nameMikeyC },
      { start : "5:04",  title : songYouveAlreadyWon,    singer : nameMikeyC },
      { start : "11:21", title : songWhatABeautifulName, singer : nameEmmyH  },
    ],
    band : [
      { position : "d", player : nameJordanP },
      { position : "b", player : nameEzrieK  },
      { position : "e", player : nameSilasB  },
      { position : "e", player : namePaulW   },
      { position : "k", player : nameBeccahR },
      { position : "a", player : nameMikeyC  },
    ],
  },
  {
    date    : "March 17, 2024",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2024-03-17",
    remix   : false,
    setlist : [
      { start : "0:00", title : songWontStopNow, singer : nameGailC   },
      { start : "4:28", title : songDoItAgain,   singer : nameCassieC },
      { start : "9:42", title : songGratitude,   singer : nameEmmaB   },
    ],
    band : [
      { position : "d", player : nameMikeyC   },
      { position : "b", player : nameMartyS   },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameJasonC   },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameCameronO },
    ],
  },
  {
    date    : "March 10, 2024",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2024-03-10",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songPraise,           singer : nameMikeyC  },
      { start : "3:34",  title : songComeThouFount,    singer : nameEmmaB   },
      { start : "6:14",  title : songThisSideOfHeaven, singer : nameHallieW },
      { start : "10:53", title : songHolyForever,      singer : nameGailC   },
    ],
    band : [
      { position : "d", player : nameEliotJ   },
      { position : "b", player : nameAlexJ    },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : namePaulW    },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameMikeyC   },
    ],
  },
  {
    date    : "March 3, 2024",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2024-03-03",
    remix   : false,
    setlist : [
      { start : "0:00", title : songSomethingGood,     singer : nameCharlieB },
      { start : "3:51", title : songGravesIntoGardens, singer : nameCharlieB },
      { start : "9:12", title : songISpeakJesus,       singer : nameGeniB    },
    ],
    band : [
      { position : "d", player : nameEliotJ   },
      { position : "b", player : nameAlexJ    },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameJasonC   },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameHallieW  },
    ],
  },
  {
    date    : "February 28, 2024",
    day     : "Wednesday",
    events  : ["Echo"],
    slug    : "2024-02-28",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songPraise,         singer : nameMikeyC },
      { start : "3:47",  title : songBeautifulJesus, singer : nameEmmyH  },
      { start : "8:28",  title : songUndividedHeart, singer : nameMikeyC },
      { start : "13:24", title : songLordINeedYou,   singer : nameEmmyH  },
    ],
    band : [
      { position : "d", player : nameJordanP },
      { position : "b", player : nameSilasB  },
      { position : "e", player : nameEzrieK  },
      { position : "k", player : nameBeccahR },
      { position : "a", player : nameMikeyC  },
    ],
  },
  {
    date    : "February 25, 2024",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2024-02-25",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songIntro,           singer : nameBand    },
      { start : "1:29",  title : songAncientGates,    singer : nameHallieW },
      { start : "6:21",  title : songFirmFoundation,  singer : nameMikeyC  },
      { start : "10:01", title : songGoodnessOfGod,   singer : nameGailC   },
      { start : "13:37", title : songThisLittleLight, singer : nameEmmaB   },
    ],
    band : [
      { position : "d", player : nameEliotJ   },
      { position : "b", player : nameAlexJ    },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameJasonC   },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameMikeyC   },
    ],
  },
  {
    date    : "February 21, 2024",
    day     : "Wednesday",
    events  : ["Echo"],
    slug    : "2024-02-21",
    remix   : false,
    setlist : [
      { start : "0:00", title : songYouveAlreadyWon, singer : nameMikeyC },
      { start : "6:07", title : songCoverTheEarth,   singer : nameEmmaB  },
      { start : "9:54", title : songRefiner,         singer : nameEmmaB  },
    ],
    band : [
      { position : "d", player : nameJordanP  },
      { position : "b", player : nameEzrieK   },
      { position : "e", player : nameMikeyC   },
      { position : "e", player : namePaulW    },
      { position : "k", player : nameShannonC },
    ],
  },
  {
    date    : "February 18, 2024",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2024-02-18",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songIntro,            singer : nameEzrieK },
      { start : "0:22",  title : songMoreThanAnything, singer : nameEmmaB  },
      { start : "2:14",  title : songThisWeKnow,       singer : nameGailC  },
      { start : "6:52",  title : songHosanna,          singer : nameEmmaB  },
      { start : "11:58", title : songISpeakJesus,      singer : nameGeniB  },
    ],
    band : [
      { position : "d", player : nameMikeyC   },
      { position : "b", player : nameAlexJ    },
      { position : "e", player : nameNickC    },
      { position : "e", player : nameJustinM  },
      { position : "k", player : nameEzrieK   },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameCameronO },
    ],
  },
  {
    date    : "February 14, 2024",
    day     : "Wednesday",
    events  : ["Echo"],
    slug    : "2024-02-14",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songYouMakeMeBrave, singer : nameHallieW },
      { start : "5:36",  title : songMoreThanAble,   singer : nameMikeyC  },
      { start : "10:24", title : songHolyForever,    singer : nameMikeyC  },
    ],
    band : [
      { position : "d", player : nameJordanP  },
      { position : "b", player : nameSilasB   },
      { position : "e", player : nameNickC    },
      { position : "e", player : namePaulW    },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameMikeyC   },
    ],
  },
  {
    date    : "February 4, 2024",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2024-02-04",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songTakeYouAtYourWord,      singer : nameGailC   },
      { start : "4:56",  title : songDoItAgain,              singer : nameGeniB   },
      { start : "6:35",  title : songGreatIsThyFaithfulness, singer : nameJasonC  },
      { start : "8:09",  title : songITrustInGod,            singer : nameGailC   },
      { start : "13:36", title : songItIsWell,               singer : nameHallieW },
    ],
    band : [
      { position : "d", player : nameEliotJ   },
      { position : "b", player : nameMartyS   },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : namePaulW    },
      { position : "k", player : nameCharlieB },
      { position : "a", player : nameJasonC   },
    ],
  },
  {
    date    : "January 31, 2024",
    day     : "Wednesday",
    events  : ["Echo"],
    slug    : "2024-01-31",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songThisIsAmazingGrace, singer : nameMikeyC  },
      { start : "4:32",  title : songBeautifulJesus,     singer : nameHallieW },
      { start : "8:34",  title : songChristBeMagnified,  singer : nameEmmyH   },
      { start : "13:28", title : songDoItAgain,          singer : nameHallieW },
    ],
    band : [
      { position : "d", player : nameJordanP  },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameMikeyC   },
    ],
  },
  {
    date    : "January 28, 2024",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2024-01-28",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songHowGreatIsOurGod, singer : nameGailC   },
      { start : "1:23",  title : songPraise,           singer : nameGailC   },
      { start : "5:21",  title : songThisSideOfHeaven, singer : nameHallieW },
      { start : "10:10", title : songWholeHeart,       singer : nameCassieC },
      { start : "14:09", title : songINeedYou,         singer : nameHallieW },
    ],
    band : [
      { position : "d", player : nameMikeyC   },
      { position : "b", player : nameAlexJ    },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameJasonC   },
      { position : "k", player : nameBeccahR  },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameCameronO },
    ],
  },
  {
    date    : "January 24, 2024",
    day     : "Wednesday",
    events  : ["Echo"],
    slug    : "2024-01-24",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songPraise,         singer : nameMikeyC },
      { start : "4:01",  title : songFirmFoundation, singer : nameMikeyC },
      { start : "10:24", title : songGoodnessOfGod,  singer : nameMikeyC },
    ],
    band : [
      { position : "d", player : nameJordanP  },
      { position : "b", player : nameEzrieK   },
      { position : "e", player : namePaulW    },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameMikeyC   },
    ],
  },
  {
    date    : "January 21, 2024",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2024-01-21",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songYouveAlreadyWon,  singer : nameMikeyC },
      { start : "6:04",  title : songSoulWillSing,     singer : nameEmmaB  },
      { start : "7:43",  title : songAnotherInTheFire, singer : nameEmmaB  },
      { start : "12:19", title : songOpenTheEyes,      singer : nameMikeyC },
    ],
    band : [
      { position : "d", player : nameEliotJ   },
      { position : "b", player : nameAlexJ    },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameJustinM  },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameMikeyC   },
    ],
  },
  {
    date    : "January 14, 2024",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2024-01-14",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songPraise,        singer : nameGailC   },
      { start : "4:03",  title : songMoreThanAble,  singer : nameHallieW },
      { start : "9:04",  title : songInChristAlone, singer : nameJasonC  },
      { start : "11:59", title : songWorthy,        singer : nameGeniB   },
    ],
    band : [
      { position : "d", player : nameMikeyC   },
      { position : "b", player : nameMartyS   },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : namePaulW    },
      { position : "k", player : nameCharlieB },
      { position : "a", player : nameJasonC   },
    ],
  },
  {
    date    : "January 10, 2024",
    day     : "Wednesday",
    events  : ["Echo"],
    slug    : "2024-01-10",
    remix   : false,
    setlist : [
      { start : "0:00", title : songPraise,          singer : nameHallieW },
      { start : "4:11", title : songYouveAlreadyWon, singer : nameMikeyC  },
    ],
    band : [
      { position : "d", player : nameJordanP  },
      { position : "b", player : nameEzrieK   },
      { position : "e", player : nameNickC    },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameMikeyC   },
    ],
  },
  {
    date    : "January 7, 2024",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2024-01-07",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songYouveAlreadyWon,  singer : nameMikeyC },
      { start : "6:02",  title : songRaiseAHallelujah, singer : nameMikeyC },
      { start : "6:37",  title : songThisSideOfHeaven, singer : nameEmmaB  },
      { start : "11:17", title : songBlessedAssurance, singer : nameGailC  },
    ],
    band : [
      { position : "d", player : nameJasonS   },
      { position : "b", player : nameMartyS   },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameCalebH   },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameMikeyC   },
    ],
  },
  {
    date    : "January 3, 2024",
    day     : "Wednesday",
    events  : ["Echo"],
    slug    : "2024-01-03",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songSurrounded,       singer : nameEmmaB },
      { start : "5:07",  title : songFirmFoundation,   singer : nameGeniB },
      { start : "11:29", title : songThisSideOfHeaven, singer : nameGeniB },
    ],
    band : [
      { position : "d", player : nameJordanP  },
      { position : "b", player : nameCalebH   },
      { position : "e", player : nameNickC    },
      { position : "e", player : nameEzrieK   },
      { position : "k", player : nameShannonC },
    ],
  },
  {
    date    : "December 24, 2023",
    day     : "Sunday",
    events  : ["Main", "Christmas Eve"],
    slug    : "2023-12-24",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songMoreThanAnything,      singer : nameEmmaB         },
      { start : "2:10",  title : songJoyToTheWorld,         singer : nameHallieW       },
      { start : "6:35",  title : songOHolyNight,            singer : nameGeniB         },
      { start : "11:21", title : songFirmFoundation,        singer : nameGailC         },
      { start : "14:37", title : songHarkTheHerald,         singer : nameEmmaB         },
      { start : "18:15", title : songTheFirstNoel,          singer : nameZachWoolhouse },
      { start : "19:08", title : songOComeOComeEmmanuel,    singer : nameEmmaB         },
      { start : "21:38", title : songGoTellItOnTheMountain, singer : nameGailC         },
      { start : "24:17", title : songOComeAllYeFaithful,    singer : nameMikeyC        },
      { start : "26:37", title : songTellMeTheStoryOfJesus, singer : nameEmmaB         },
      { start : "28:26", title : songAwayInAManger,         singer : nameMikeyC        },
      { start : "30:21", title : songSilentNight,           singer : nameEmmaB         },
    ],
    band : [
      { position : "d", player : nameMikeyC   },
      { position : "b", player : nameMartyS   },
      { position : "e", player : nameNickC    },
      { position : "e", player : nameCalebH   },
      { position : "k", player : nameShannonC },
      { position : "k", player : nameEzrieK   },
      { position : "a", player : nameJasonC   },
    ],
  },
  {
    date    : "December 10, 2023",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2023-12-10",
    remix   : false,
    setlist : [
      { start : "0:00", title : songSurrounded, singer : nameEmmaB   },
      { start : "5:03", title : songTheStand,   singer : nameMikeyC  },
      { start : "7:18", title : songLivingHope, singer : nameHallieW },
    ],
    band : [
      { position : "d", player : nameEliotJ   },
      { position : "b", player : nameMartyS   },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameCalebH   },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameMikeyC   },
    ],
  },
  {
    date    : "November 29, 2023",
    day     : "Wednesday",
    events  : ["Echo"],
    slug    : "2023-11-29",
    remix   : false,
    setlist : [
      { start : "0:00", title : songLoveShines,       singer : nameMikeyC },
      { start : "5:28", title : songCoverTheEarth,    singer : nameEmmaB  },
      { start : "9:25", title : songThisSideOfHeaven, singer : nameEmmaB  },
    ],
    band : [
      { position : "d", player : nameJordanP  },
      { position : "b", player : nameCalebH   },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameCollinS  },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameMikeyC   },
    ],
  },
  {
    date    : "November 28, 2023",
    day     : "Tuesday",
    events  : ["Celebration of Life"],
    slug    : "2023-11-28",
    remix   : false,
    setlist : [
      { start : "0:00", title : songYouveAlreadyWon,    singer : nameJeffM   },
      { start : "5:42", title : songWhatHesDone,        singer : nameMorganL },
      { start : "9:32", title : songTheGoodnessOfJesus, singer : nameJeffM   },
    ],
    band : [
      { position : "d", player : nameMikeyC   },
      { position : "b", player : nameMartyS   },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameJaredL   },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameJeffM    },
    ],
  },
  {
    date    : "November 26, 2023",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2023-11-26",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songIntro,             singer : nameBand    },
      { start : "1:57",  title : songGravesIntoGardens, singer : nameGailC   },
      { start : "6:12",  title : songThisSideOfHeaven,  singer : nameHallieW },
      { start : "11:14", title : songGratitude,         singer : nameMikeyC  },
    ],
    band : [
      { position : "d", player : nameEliotJ   },
      { position : "b", player : nameAlexJ    },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameJasonC   },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameMikeyC   },
    ],
  },
  {
    date    : "November 19, 2023",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2023-11-19",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songMuzak,              singer : nameBand    },
      { start : "2:01",  title : songBeGlad,             singer : nameCassieC },
      { start : "7:01",  title : songHolyHolyHoly,       singer : nameGeniB   },
      { start : "7:58",  title : songITrustInGod,        singer : nameGailC   },
      { start : "13:23", title : songWhatABeautifulName, singer : nameGeniB   },
    ],
    band : [
      { position : "d", player : nameJasonS   },
      { position : "b", player : nameAlexJ    },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameJasonC   },
      { position : "k", player : nameShannonC },
    ],
  },
  {
    date    : "November 12, 2023",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2023-11-12",
    remix   : false,
    setlist : [
      { start : "0:00", title : songTakeYouAtYourWord, singer : nameSamD  },
      { start : "5:04", title : songCoverTheEarth,     singer : nameEmmaB },
      { start : "9:46", title : songRefiner,           singer : nameEmmaB },
    ],
    band : [
      { position : "d", player : nameMikeyC   },
      { position : "b", player : nameMartyS   },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameCalebH   },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameSamD     },
    ],
  },
  {
    date    : "November 8, 2023",
    day     : "Wednesday",
    events  : ["Echo"],
    slug    : "2023-11-08",
    remix   : false,
    setlist : [
      { start : "0:00", title : songYouMakeMeBrave,    singer : nameHallieW },
      { start : "3:32", title : songChristBeMagnified, singer : nameEmmyH   },
      { start : "8:31", title : songThisSideOfHeaven,  singer : nameHallieW },
    ],
    band : [
      { position : "d", player : nameMikeyC   },
      { position : "b", player : nameEzrieK   },
      { position : "e", player : nameCollinS  },
      { position : "e", player : nameJustinM  },
      { position : "k", player : nameShannonC },
    ],
  },
  {
    date    : "November 5, 2023",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2023-11-05",
    remix   : false,
    setlist : [
      { start : "0:00", title : songAncientGates, singer : nameHallieW },
      { start : "4:33", title : songTheBlessing,  singer : nameGeniB   },
    ],
    band : [
      { position : "d", player : nameJoshM    },
      { position : "b", player : nameAlexJ    },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameJustinM  },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameHallieW  },
    ],
  },
  {
    date    : "November 1, 2023",
    day     : "Wednesday",
    events  : ["Echo"],
    slug    : "2023-11-01",
    remix   : false,
    setlist : [
      { start : "0:00", title : songThisIsOurGod,     singer : nameHallieW },
      { start : "4:01", title : songAnotherInTheFire, singer : nameEmmyH   },
      { start : "8:39", title : songGratitude,        singer : nameHallieW },
    ],
    band : [
      { position : "d", player : nameMikeyC   },
      { position : "b", player : nameBrianO   },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameCollinS  },
      { position : "k", player : nameShannonC },
    ],
  },
  {
    date    : "October 15, 2023",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2023-10-15",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songThisIsOurGod,   singer : nameHallieW },
      { start : "4:19",  title : songBeautifulJesus, singer : nameEmmaB   },
      { start : "9:21",  title : songITrustInGod,    singer : nameGailC   },
      { start : "15:44", title : songWayMaker,       singer : nameJasonC  },
    ],
    band : [
      { position : "d", player : nameMikeyC   },
      { position : "b", player : nameAlexJ    },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameJustinM  },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameJasonC   },
    ],
  },
  {
    date    : "October 4, 2023",
    day     : "Wednesday",
    events  : ["Echo"],
    slug    : "2023-10-04",
    remix   : false,
    setlist : [
      { start : "0:00", title : songSomethingGood, singer : nameEmmyH  },
      { start : "4:03", title : songGoodnessOfGod, singer : nameMikeyC },
      { start : "8:21", title : songGratitude,     singer : nameMikeyC },
    ],
    band : [
      { position : "d", player : nameEliotJ   },
      { position : "b", player : nameEzrieK   },
      { position : "e", player : namePaulW    },
      { position : "e", player : nameCollinS  },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameMikeyC   },
    ],
  },
  {
    date    : "October 1, 2023",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2023-10-01",
    remix   : false,
    setlist : [
      { start : "0:00", title : songSetAFire,       singer : nameHallieW },
      { start : "3:39", title : songPsalm23,        singer : nameSamD    },
      { start : "8:52", title : songFirmFoundation, singer : nameGailC   },
    ],
    band : [
      { position : "d", player : nameMikeyC   },
      { position : "b", player : nameMartyS   },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameJasonC   },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameSamD     },
    ],
  },
  {
    date    : "September 27, 2023",
    day     : "Wednesday",
    events  : ["Echo"],
    slug    : "2023-09-27",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songYouMakeMeBrave,    singer : nameHallieW },
      { start : "6:25",  title : songSurrounded,        singer : nameHallieW },
      { start : "12:24", title : songAllThingsTogether, singer : nameMikeyC  },
    ],
    band : [
      { position : "d", player : nameJordanP  },
      { position : "b", player : nameCalebH   },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameCollinS  },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameMikeyC   },
    ],
  },
  {
    date    : "September 24, 2023",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2023-09-24",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songTakeYouAtYourWord, singer : nameMikeyC },
      { start : "5:06",  title : songBeautifulJesus,    singer : nameEmmaB  },
      { start : "9:41",  title : songLivingHope,        singer : nameGeniB  },
      { start : "14:27", title : songRefiner,           singer : nameEmmaB  },
    ],
    band : [
      { position : "d", player : nameEliotJ   },
      { position : "b", player : nameAlexJ    },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameJustinM  },
      { position : "k", player : nameCharlieB },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameMikeyC   },
    ],
  },
  {
    date    : "September 17, 2023",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2023-09-17",
    remix   : false,
    setlist : [
      { start : "0:00", title : songGiveMeFaith,    singer : nameGailC   },
      { start : "3:48", title : songJesusPaidItAll, singer : nameBeccahR },
      { start : "8:23", title : songINeedYou,       singer : nameHallieW },
    ],
    band : [
      { position : "d", player : nameMikeyC   },
      { position : "b", player : nameTylerC   },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameCollinS  },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameHallieW  },
    ],
  },
  {
    date    : "September 13, 2023",
    day     : "Wednesday",
    events  : ["Echo"],
    slug    : "2023-09-13",
    remix   : false,
    setlist : [
      { start : "0:00", title : songGreatThings,    singer : nameEmmyH  },
      { start : "4:21", title : songComeWhatMay,    singer : nameMikeyC },
      { start : "7:50", title : songFirmFoundation, singer : nameMikeyC },
    ],
    band : [
      { position : "d", player : nameJordanP  },
      { position : "b", player : nameCalebH   },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameCollinS  },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameMikeyC   },
    ],
  },
  {
    date    : "September 10, 2023",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2023-09-10",
    remix   : false,
    setlist : [
      { start : "0:27", title : songDoxology,      singer : nameGailC  },
      { start : "2:01", title : songBeGlad,        singer : nameGailC  },
      { start : "7:02", title : songComeThouFount, singer : nameGeniB  },
      { start : "9:52", title : songGratitude,     singer : nameMikeyC },
    ],
    band : [
      { position : "d", player : nameJoshM    },
      { position : "b", player : nameMartyS   },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameJasonC   },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameMikeyC   },
    ],
  },
  {
    date    : "September 3, 2023",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2023-09-03",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songTakeYouAtYourWord, singer : nameGailC   },
      { start : "4:57",  title : songAncientGates,      singer : nameHallieW },
      { start : "10:04", title : songRevelationSong,    singer : nameEmmaB   },
      { start : "14:43", title : songOComeToTheAltar,   singer : nameHallieW },
    ],
    band : [
      { position : "d", player : nameMikeyC   },
      { position : "b", player : nameAlexJ    },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameCollinS  },
      { position : "k", player : nameBeccahR  },
      { position : "k", player : nameShannonC },
    ],
  },
  {
    date    : "August 30, 2023",
    day     : "Wednesday",
    events  : ["Echo"],
    slug    : "2023-08-30",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songThisIsOurGod,     singer : nameHallieW },
      { start : "4:17",  title : songOverAndOver,      singer : nameMikeyC  },
      { start : "10:42", title : songAnotherInTheFire, singer : nameHallieW },
      { start : "15:16", title : songBuildMyLife,      singer : nameMikeyC  },
    ],
    band : [
      { position : "d", player : nameJordanP  },
      { position : "b", player : nameEzrieK   },
      { position : "e", player : namePaulW    },
      { position : "e", player : nameCollinS  },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameMikeyC   },
    ],
  },
  {
    date    : "August 27, 2023",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2023-08-27",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songGreatThings, singer : nameBeccahR },
      { start : "4:26",  title : songITrustInGod, singer : nameGailC   },
      { start : "10:26", title : songTremble,     singer : nameGeniB   },
      { start : "14:18", title : songISpeakJesus, singer : nameGeniB   },
    ],
    band : [
      { position : "d", player : nameEliotJ   },
      { position : "b", player : nameMartyS   },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameJustinM  },
      { position : "k", player : nameCharlieB },
      { position : "k", player : nameShannonC },
    ],
  },
  {
    date    : "August 23, 2023",
    day     : "Wednesday",
    events  : ["Echo"],
    slug    : "2023-08-23",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songTakeYouAtYourWord, singer : nameMikeyC },
      { start : "5:14",  title : songCoverTheEarth,     singer : nameEmmaB  },
      { start : "9:10",  title : songHosanna,           singer : nameEmmaB  },
      { start : "14:06", title : songRefiner,           singer : nameEmmaB  },
    ],
    band : [
      { position : "d", player : nameJordanP  },
      { position : "b", player : nameEzrieK   },
      { position : "e", player : nameNickC    },
      { position : "e", player : namePaulW    },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameMikeyC   },
    ],
  },
  {
    date    : "August 20, 2023",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2023-08-20",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songThisIsOurGod, singer : nameHallieW },
      { start : "4:13",  title : songMoreThanAble, singer : nameGailC   },
      { start : "8:53",  title : songResurrender,  singer : nameEmmaB   },
      { start : "10:35", title : songLivingHope,   singer : nameEricaS  },
    ],
    band : [
      { position : "d", player : nameEliotJ   },
      { position : "b", player : nameEzrieK   },
      { position : "e", player : nameJasonC   },
      { position : "e", player : nameCollinS  },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameHallieW  },
    ],
  },
  {
    date    : "August 19, 2023",
    day     : "Saturday",
    events  : ["Ascent Conference"],
    slug    : "2023-08-19",
    remix   : false,
    setlist : [
      { start : "0:00", title : songAncientGates,   singer : nameGailC   },
      { start : "4:45", title : songThisIsOurGod,   singer : nameHallieW },
      { start : "9:07", title : songIveWitnessedIt, singer : nameGeniB   },
    ],
    band : [
      { position : "d", player : nameEliotJ   },
      { position : "b", player : nameAlexJ    },
      { position : "e", player : namePaulW    },
      { position : "e", player : nameJustinM  },
      { position : "a", player : nameHallieW  },
      { position : "k", player : nameCharlieB },
      { position : "k", player : nameShannonC },
    ],
  },
  {
    date    : "August 13, 2023",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2023-08-13",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songThisIsOurGod,   singer : nameCharlieB },
      { start : "4:14",  title : songIveWitnessedIt, singer : nameGeniB    },
      { start : "9:38",  title : songJesusYouAlone,  singer : nameEmmaB    },
      { start : "15:08", title : songWorthyOfItAll,  singer : nameCharlieB },
    ],
    band : [
      { position : "d", player : nameJoshM    },
      { position : "b", player : nameMartyS   },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameCollinS  },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameJasonC   },
    ],
  },
  {
    date    : "July 23, 2023",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2023-07-23",
    remix   : false,
    setlist : [
      { start : "0:00", title : songBattleBelongs,     singer : nameJasonC  },
      { start : "4:24", title : songChristBeMagnified, singer : nameBeccahR },
      { start : "9:37", title : songIveWitnessedIt,    singer : nameEmmaD   },
    ],
    band : [
      { position : "d", player : nameJoshM    },
      { position : "b", player : nameAlexJ    },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : namePaulW    },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameJasonC   },
    ],
  },
  {
    date    : "July 16, 2023",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2023-07-16",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songGoodnessOfGod,        singer : nameEmmaD    },
      { start : "5:52",  title : songGravesIntoGardens,    singer : nameCharlieB },
      { start : "10:01", title : songAThousandHallelujahs, singer : nameEmmaB    },
      { start : "11:57", title : songGreaterThan,          singer : nameGeniB    },
      { start : "16:53", title : songWorthy,               singer : nameEmmaB    },
    ],
    band : [
      { position : "d", player : nameJasonS   },
      { position : "b", player : nameAlexJ    },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameTylerC   },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameJasonC   },
    ],
  },
  {
    date    : "July 11, 2023",
    day     : "Tuesday",
    events  : ["Stay Camp, Day 2"],
    slug    : "2023-07-11",
    remix   : false,
    setlist : [
      { start : "0:00", title : songAwakeMySoul,        singer : nameEmmaD   },
      { start : "3:52", title : songFaithAndWonder,     singer : nameEmmaD   },
      { start : "9:05", title : songWhatABeautifulName, singer : nameHallieW },
    ],
    band : [
      { position : "d", player : nameJordanP },
      { position : "b", player : nameBrianO  },
      { position : "e", player : nameEzrieK  },
      { position : "e", player : namePaulW   },
      { position : "k", player : nameBeccahR },
    ],
  },
  {
    date    : "July 10, 2023",
    day     : "Monday",
    events  : ["Stay Camp, Day 1"],
    slug    : "2023-07-10",
    remix   : false,
    setlist : [
      { start : "0:00", title : songTakeYouAtYourWord, singer : nameEmmaD   },
      { start : "5:03", title : songBeautifulJesus,    singer : nameHallieW },
      { start : "9:42", title : songAnotherInTheFire,  singer : nameHallieW },
    ],
    band : [
      { position : "d", player : nameJordanP },
      { position : "b", player : nameBrianO  },
      { position : "e", player : nameEzrieK  },
      { position : "e", player : namePaulW   },
      { position : "k", player : nameBeccahR },
    ],
  },
  {
    date    : "July 9, 2023",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2023-07-09",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songHouseOfTheLord, singer : nameCharlieB },
      { start : "3:58",  title : songThisIsOurGod,   singer : nameHallieW  },
      { start : "10:32", title : songRevelationSong, singer : nameEmmaB    },
      { start : "15:32", title : songFirmFoundation, singer : nameGailC    },
    ],
    band : [
      { position : "d", player : nameEliotJ   },
      { position : "b", player : nameAlexJ    },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameTylerC   },
      { position : "k", player : nameBeccahR  },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameHallieW  },
    ],
  },
  {
    date    : "July 2, 2023",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2023-07-02",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songWhoIsLikeTheLord, singer : nameCharlieB },
      { start : "5:03",  title : songGoodnessOfGod,    singer : nameHallieW  },
      { start : "10:26", title : songBeautifulJesus,   singer : nameGeniB    },
      { start : "13:15", title : songGreaterThan,      singer : nameGeniB    },
    ],
    band : [
      { position : "d", player : nameJasonS   },
      { position : "b", player : nameEzrieK   },
      { position : "e", player : namePaulW    },
      { position : "e", player : nameJasonC   },
      { position : "k", player : nameCharlieB },
    ],
  },
  {
    date    : "June 25, 2023",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2023-06-25",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songSomethingGood, singer : nameCharlieB },
      { start : "5:16",  title : songJehovah,       singer : nameGailC    },
      { start : "10:17", title : songMoreThanAble,  singer : nameHallieW  },
      { start : "15:14", title : songNothingElse,   singer : nameGeniB    },
    ],
    band : [
      { position : "d", player : nameEliotJ   },
      { position : "b", player : nameAlexJ    },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameCollinS  },
      { position : "k", player : nameBeccahR  },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameHallieW  },
    ],
  },
  {
    date    : "June 11, 2023",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2023-06-11",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songTakeYouAtYourWord,    singer : nameGailC   },
      { start : "5:11",  title : songYesIWill,             singer : nameJasonC  },
      { start : "8:59",  title : songAThousandHallelujahs, singer : nameGeniB   },
      { start : "13:54", title : songOPraiseTheName,       singer : nameBeccahR },
    ],
    band : [
      { position : "d", player : nameEliotJ   },
      { position : "b", player : nameMartyS   },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameNickC    },
      { position : "k", player : nameCharlieB },
      { position : "a", player : nameJasonC   },
    ],
  },
  {
    date    : "May 28, 2023",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2023-05-28",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songGodSoLoved,        singer : nameHallieW },
      { start : "4:03",  title : songTakeYouAtYourWord, singer : nameGailC   },
      { start : "9:04",  title : songWorthyWorthy,      singer : nameEmmaB   },
      { start : "13:38", title : songISurrender,        singer : nameHallieW },
    ],
    band : [
      { position : "d", player : nameEliotJ   },
      { position : "b", player : nameAlexJ    },
      { position : "e", player : namePaulW    },
      { position : "e", player : nameJustinM  },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameHallieW  },
    ],
  },
  {
    date    : "May 24, 2023",
    day     : "Wednesday",
    events  : ["Echo"],
    slug    : "2023-05-24",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songTakeYouAtYourWord,    singer : nameEmmaD   },
      { start : "5:11",  title : songAThousandHallelujahs, singer : nameHallieW },
      { start : "10:54", title : songGratitude,            singer : nameHallieW },
    ],
    band : [
      { position : "d", player : nameJordanP  },
      { position : "b", player : nameEzrieK   },
      { position : "e", player : nameMikeyC   },
      { position : "e", player : namePaulW    },
      { position : "k", player : nameShannonC },
    ],
  },
  {
    date    : "May 21, 2023",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2023-05-21",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songTakeYouAtYourWord, singer : nameMikeyC },
      { start : "6:47",  title : songMightyRedeemer,    singer : nameGailC  },
      { start : "11:28", title : songHowGreatThouArt,   singer : nameEricaS },
      { start : "14:23", title : songJesusYouAlone,     singer : nameEmmaB  },
    ],
    band : [
      { position : "d", player : nameEliotJ   },
      { position : "b", player : nameAlexJ    },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameJustinM  },
      { position : "k", player : nameShannonC },
      { position : "k", player : nameCharlieB },
      { position : "a", player : nameMikeyC   },
    ],
  },
  {
    date    : "May 17, 2023",
    day     : "Wednesday",
    events  : ["Echo"],
    slug    : "2023-05-17",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songTakeYouAtYourWord, singer : nameMikeyC },
      { start : "5:19",  title : songDesertSong,        singer : nameEmmaB  },
      { start : "9:52",  title : songBeautifulJesus,    singer : nameEmmaB  },
      { start : "15:43", title : songFirmFoundation,    singer : nameMikeyC },
    ],
    band : [
      { position : "d", player : nameJordanP  },
      { position : "b", player : nameEzrieK   },
      { position : "e", player : nameNickC    },
      { position : "e", player : namePaulW    },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameMikeyC   },
    ],
  },
  {
    date    : "May 14, 2023",
    day     : "Sunday",
    events  : ["Main", "Mother's Day"],
    slug    : "2023-05-14",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songGreatThings,        singer : nameGailC },
      { start : "4:21",  title : songChristBeMagnified,  singer : nameSamD  },
      { start : "9:12",  title : songWhatABeautifulName, singer : nameEmmaB },
      { start : "14:23", title : songTheBlessing,        singer : nameGeniB },
    ],
    band : [
      { position : "d", player : nameMikeyC   },
      { position : "b", player : nameMartyS   },
      { position : "e", player : nameEzrieK   },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameSamD     },
    ],
  },
  {
    date    : "May 10, 2023",
    day     : "Wednesday",
    events  : ["Echo"],
    slug    : "2023-05-10",
    remix   : false,
    setlist : [
      { start : "0:00", title : songHouseOfTheLord,     singer : nameMikeyC },
      { start : "3:44", title : songMoreThanAble,       singer : nameEmmaB  },
      { start : "8:32", title : songWhatABeautifulName, singer : nameEmmaB  },
    ],
    band : [
      { position : "d", player : nameJordanP  },
      { position : "b", player : nameEzrieK   },
      { position : "e", player : namePaulW    },
      { position : "e", player : nameCollinS  },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameMikeyC   },
    ],
  },
  {
    date    : "May 7, 2023",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2023-05-07",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songWhoIsLikeTheLord,   singer : nameCharlieB },
      { start : "4:58",  title : songNothingButTheBlood, singer : nameMikeyC   },
      { start : "6:34",  title : songGoodnessOfGod,      singer : nameEmmaB    },
      { start : "10:34", title : songGratitude,          singer : nameMikeyC   },
    ],
    band : [
      { position : "d", player : nameJasonS   },
      { position : "b", player : nameAlexJ    },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameJustinM  },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameMikeyC   },
    ],
  },
  {
    date    : "April 30, 2023",
    day     : "Sunday",
    events  : ["Main", "Baptism"],
    slug    : "2023-04-30",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songHouseOfTheLord,   singer : nameGailC   },
      { start : "4:58",  title : songJesusPaidItAll,   singer : nameBeccahR },
      { start : "9:59",  title : songMoreThanAble,     singer : nameEricaS  },
      { start : "14:38", title : songFromTheInsideOut, singer : nameGeniB   },
    ],
    band : [
      { position : "d", player : nameMikeyC   },
      { position : "b", player : nameAlexJ    },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameJasonC   },
      { position : "k", player : nameShannonC },
      { position : "k", player : nameCharlieB },
    ],
  },
  {
    date    : "April 23, 2023",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2023-04-23",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songDoxology,     singer : nameGailC   },
      { start : "2:19",  title : songAncientGates, singer : nameHallieW },
      { start : "6:51",  title : songMoreThanAble, singer : nameGailC   },
      { start : "11:49", title : songWholeHeart,   singer : nameEmmaB   },
      { start : "16:24", title : songAmazingGrace, singer : nameEmmaB   },
    ],
    band : [
      { position : "d", player : nameMikeyC   },
      { position : "b", player : nameAlexJ    },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameJasonC   },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameHallieW  },
    ],
  },
  {
    date    : "April 16, 2023",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2023-04-16",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songComeAlive,   singer : nameEricaS },
      { start : "4:31",  title : songYouCame,     singer : nameEmmaB  },
      { start : "8:48",  title : songComeWhatMay, singer : nameMikeyC },
      { start : "12:27", title : songNothingElse, singer : nameGailC  },
    ],
    band : [
      { position : "d", player : nameEliotJ   },
      { position : "b", player : nameTylerC   },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameJasonC   },
      { position : "k", player : nameCharlieB },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameMikeyC   },
    ],
  },
  {
    date    : "April 15, 2023",
    day     : "Saturday",
    events  : ["Bloomcon"],
    slug    : "2023-04-15",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songHouseOfTheLord,         singer : nameMatthewH },
      { start : "4:13",  title : songWeWelcomeYouWithPraise, singer : nameMatthewH },
      { start : "5:34",  title : songGoodnessOfGod,          singer : nameJessieH  },
      { start : "11:47", title : songGoodGoodFather,         singer : nameMatthewH },
      { start : "14:29", title : songFirmFoundation,         singer : nameMatthewH },
    ],
    band : [
      { position : "d", player : nameMikeyC   },
      { position : "b", player : nameAlexJ    },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameJasonC   },
      { position : "k", player : nameCharlieB },
      { position : "a", player : nameMatthewH },
    ],
  },
  {
    date    : "April 12, 2023",
    day     : "Wednesday",
    events  : ["Echo"],
    slug    : "2023-04-12",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songUnstoppableGod, singer : nameCharlieB },
      { start : "4:17",  title : songIndescribable,  singer : nameCharlieB },
      { start : "8:04",  title : songHaveMyHeart,    singer : nameCharlieB },
      { start : "12:35", title : songForeverYours,   singer : nameGeniB    },
    ],
    band : [
      { position : "d", player : nameEliotJ   },
      { position : "b", player : nameEzrieK   },
      { position : "e", player : nameMikeyC   },
      { position : "e", player : namePaulW    },
      { position : "k", player : nameShannonC },
    ],
  },
  {
    date    : "April 9, 2023",
    day     : "Sunday",
    events  : ["Main", "Easter"],
    slug    : "2023-04-09",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songBlessedAssurance, singer : nameGailC  },
      { start : "3:58",  title : songSundayIsComing,   singer : nameMikeyC },
      { start : "8:46",  title : songHosanna,          singer : nameEmmaB  },
      { start : "13:38", title : songISpeakJesus,      singer : nameGeniB  },
      { start : "15:45", title : songDeathWasArrested, singer : nameMikeyC },
      { start : "21:02", title : songClean,            singer : nameEmmaB  },
    ],
    band : [
      { position : "d", player : nameEliotJ   },
      { position : "b", player : nameMartyS   },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameJasonC   },
      { position : "k", player : nameShannonC },
      { position : "k", player : nameCharlieB },
      { position : "a", player : nameMikeyC   },
    ],
  },
  {
    date    : "April 5, 2023",
    day     : "Wednesday",
    events  : ["Echo"],
    slug    : "2023-04-05",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songGloriousDay,     singer : nameMikeyC },
      { start : "4:08",  title : songBeautifulJesus,  singer : nameEmmaB  },
      { start : "8:52",  title : songFirmFoundation,  singer : nameMikeyC },
      { start : "14:11", title : songGreatAreYouLord, singer : nameMikeyC },
    ],
    band : [
      { position : "d", player : nameJordanP  },
      { position : "b", player : nameEzrieK   },
      { position : "e", player : namePaulW    },
      { position : "e", player : nameCollinS  },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameMikeyC   },
    ],
  },
  {
    date    : "April 2, 2023",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2023-04-02",
    remix   : false,
    setlist : [
      { start : "0:00", title : songAncientGates, singer : nameCassieC },
      { start : "4:36", title : songHosanna,      singer : nameEricaS  },
      { start : "9:14", title : songMakeRoom,     singer : nameCassieC },
    ],
    band : [
      { position : "d", player : nameEliotJ  },
      { position : "b", player : nameAlexJ   },
      { position : "e", player : nameEzrieK  },
      { position : "e", player : nameTylerC  },
      { position : "a", player : nameJasonC  },
      { position : "k", player : nameBeccahR },
    ],
  },
  {
    date    : "March 26, 2023",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2023-03-26",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songGloriousDay,    singer : nameCoaloZ },
      { start : "4:31",  title : songFirmFoundation, singer : nameCoaloZ },
      { start : "13:08", title : songGoodnessOfGod,  singer : nameCoaloZ },
      { start : "17:24", title : songRevelationSong, singer : nameGeniB  },
    ],
    band : [
      { position : "d", player : nameMikeyC   },
      { position : "b", player : nameAlexJ    },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameTylerC   },
      { position : "a", player : nameJasonC   },
      { position : "k", player : nameCharlieB },
    ],
  },
  {
    date    : "March 22, 2023",
    day     : "Wednesday",
    events  : ["Echo"],
    slug    : "2023-03-22",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songHereForYou,     singer : nameCharlieB },
      { start : "5:41",  title : songOpenTheHeavens, singer : nameGeniB    },
      { start : "9:21",  title : songHaveMyHeart,    singer : nameCharlieB },
      { start : "14:25", title : songMakeRoom,       singer : nameGeniB    },
    ],
    band : [
      { position : "d", player : nameJordanP  },
      { position : "b", player : nameBrianO   },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : namePaulW    },
      { position : "k", player : nameShannonC },
    ],
  },
  {
    date    : "March 19, 2023",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2023-03-19",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songFamousOne,       singer : nameHallieW },
      { start : "4:02",  title : songISpeakJesus,     singer : nameEricaS  },
      { start : "9:48",  title : songFirmFoundation,  singer : nameGailC   },
      { start : "14:52", title : songGreatAreYouLord, singer : nameKyleG   },
    ],
    band : [
      { position : "d", player : nameMikeyC   },
      { position : "b", player : nameAlexJ    },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : namePaulW    },
      { position : "a", player : nameJasonC   },
      { position : "k", player : nameCharlieB },
    ],
  },
  {
    date    : "March 12, 2023",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2023-03-12",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songHowMarvelous,    singer : nameSamD  },
      { start : "1:11",  title : songRecklessLove,    singer : nameGailC },
      { start : "6:17",  title : songReignAboveItAll, singer : nameSamD  },
      { start : "11:01", title : songLivingHope,      singer : nameGeniB },
      { start : "15:19", title : songSoulWillSing,    singer : nameEmmaB },
    ],
    band : [
      { position : "d", player : nameJasonS   },
      { position : "b", player : nameMartyS   },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : namePaulW    },
      { position : "a", player : nameSamD     },
      { position : "k", player : nameShannonC },
    ],
  },
  {
    date    : "March 8, 2023",
    day     : "Wednesday",
    events  : ["Echo"],
    slug    : "2023-03-08",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songHeIsFaithful,      singer : nameMikeyC },
      { start : "4:08",  title : songAllThingsTogether, singer : nameMikeyC },
      { start : "9:09",  title : songGoodnessOfGod,     singer : nameEmmyH  },
      { start : "13:06", title : songWayMaker,          singer : nameEmmyH  },
    ],
    band : [
      { position : "d", player : nameJordanP  },
      { position : "b", player : nameTylerC   },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : namePaulW    },
      { position : "a", player : nameMikeyC   },
      { position : "k", player : nameShannonC },
    ],
  },
  {
    date    : "March 5, 2023",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2023-03-05",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songHisNameIsJesus,   singer : nameGailC  },
      { start : "1:45",  title : songTheWay,           singer : nameJasonC },
      { start : "6:06",  title : songLeadMeToTheCross, singer : nameEmmaB  },
      { start : "10:29", title : songBlessedAssurance, singer : nameGailC  },
    ],
    band : [
      { position : "d", player : nameMikeyC   },
      { position : "b", player : nameMartyS   },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameNickC    },
      { position : "a", player : nameJasonC   },
      { position : "k", player : nameShannonC },
    ],
  },
  {
    date    : "February 26, 2023",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2023-02-26",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songSetAFire,          singer : nameHallieW },
      { start : "3:10",  title : songChristBeMagnified, singer : nameEricaS  },
      { start : "7:18",  title : songFirmFoundation,    singer : nameMikeyC  },
      { start : "12:54", title : songRefiner,           singer : nameEmmaB   },
    ],
    band : [
      { position : "d", player : nameEliotJ   },
      { position : "b", player : nameMartyS   },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameJasonC   },
      { position : "a", player : nameMikeyC   },
      { position : "k", player : nameShannonC },
    ],
  },
  {
    date    : "February 19, 2023",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2023-02-19",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songHouseOfTheLord, singer : nameBeccahR },
      { start : "4:09",  title : songMightyRedeemer, singer : nameJasonC  },
      { start : "8:46",  title : songNoOtherName,    singer : nameGailC   },
      { start : "15:43", title : songISpeakJesus,    singer : nameGeniB   },
    ],
    band : [
      { position : "d", player : nameEliotJ   },
      { position : "b", player : nameAlexJ    },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameTylerC   },
      { position : "a", player : nameJasonC   },
      { position : "k", player : nameCharlieB },
    ],
  },
  {
    date    : "February 15, 2023",
    day     : "Wednesday",
    events  : ["Echo"],
    slug    : "2023-02-15",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songSomethingGood,     singer : nameCharlieB },
      { start : "3:49",  title : songSameGod,           singer : nameGeniB    },
      { start : "10:19", title : songGoodnessOfTheLord, singer : nameCharlieB },
      { start : "14:57", title : songISpeakJesus,       singer : nameGeniB    },
    ],
    band : [
      { position : "d", player : nameJordanP },
      { position : "b", player : nameBrianO  },
      { position : "e", player : nameEzrieK  },
      { position : "e", player : namePaulW   },
      { position : "k", player : nameBeccahR },
    ],
  },
  {
    date    : "February 12, 2023",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2023-02-12",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songDoxology,    singer : nameGailC  },
      { start : "2:18",  title : songComeWhatMay, singer : nameMikeyC },
      { start : "5:31",  title : songGratitude,   singer : nameMikeyC },
      { start : "10:14", title : songTremble,     singer : nameGeniB  },
    ],
    band : [
      { position : "a", player : nameMikeyC },
      { position : "a", player : nameEzrieK },
    ],
  },
  {
    date    : "February 8, 2023",
    day     : "Wednesday",
    events  : ["Echo"],
    slug    : "2023-02-08",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songTheLionAndTheLamb,      singer : nameMikeyC },
      { start : "3:58",  title : songNothingIsHoldingMeBack, singer : nameMikeyC },
      { start : "8:27",  title : songAnotherInTheFire,       singer : nameAriR   },
      { start : "13:02", title : songGratitude,              singer : nameMikeyC },
    ],
    band : [
      { position : "d", player : nameJordanP },
      { position : "b", player : nameBrianO  },
      { position : "e", player : nameEzrieK  },
      { position : "e", player : nameCollinS },
      { position : "k", player : nameBeccahR },
      { position : "a", player : nameMikeyC  },
    ],
  },
  {
    date    : "February 5, 2023",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2023-02-05",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songComeAlive,         singer : nameEricaS },
      { start : "4:41",  title : songChristBeMagnified, singer : nameSamD   },
      { start : "9:25",  title : songFirmFoundation,    singer : nameGailC  },
      { start : "16:41", title : songRevelationSong,    singer : nameEmmaB  },
    ],
    band : [
      { position : "d", player : nameMikeyC   },
      { position : "b", player : nameMartyS   },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameJasonC   },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameSamD     },
    ],
  },
  {
    date    : "January 29, 2023",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2023-01-29",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songNothingElse,        singer : nameGeniB   },
      { start : "4:30",  title : songEgypt,              singer : nameMikeyC  },
      { start : "8:45",  title : songWhatABeautifulName, singer : nameKelleyD },
      { start : "14:27", title : songDryBones,           singer : nameGailC   },
    ],
    band : [
      { position : "d", player : nameJasonS   },
      { position : "b", player : nameBrianO   },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameJasonC   },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameMikeyC   },
    ],
  },
  {
    date    : "January 22, 2023",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2023-01-22",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songDoxology,     singer : nameCadenW },
      { start : "2:10",  title : songGiveMeFaith,  singer : nameGailC  },
      { start : "6:04",  title : songForeverReign, singer : nameGeniB  },
      { start : "11:27", title : songHosanna,      singer : nameEmmaB  },
      { start : "16:19", title : songDoxology,     singer : nameEmmaB  },
    ],
    band : [
      { position : "d", player : nameMikeyC   },
      { position : "b", player : nameAlexJ    },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameNickC    },
      { position : "k", player : nameShannonC },
    ],
  },
  {
    date    : "January 15, 2023",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2023-01-15",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songGodSoLoved,     singer : nameCassieC },
      { start : "5:03",  title : songSameGod,        singer : nameGailC   },
      { start : "10:01", title : songJesusPaidItAll, singer : nameBeccahR },
      { start : "14:59", title : songISpeakJesus,    singer : nameEricaS  },
    ],
    band : [
      { position : "d", player : nameEliotJ   },
      { position : "b", player : nameAlexJ    },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameJustinM  },
      { position : "k", player : nameCharlieB },
    ],
  },
  {
    date    : "January 11, 2023",
    day     : "Wednesday",
    events  : ["Echo"],
    slug    : "2023-01-11",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songUnstoppableGod, singer : nameCadenW },
      { start : "3:52",  title : songEgypt,          singer : nameMikeyC },
      { start : "8:16",  title : songRunToTheFather, singer : nameCadenW },
      { start : "13:07", title : songGoodnessOfGod,  singer : nameEmmaD  },
    ],
    band : [
      { position : "d", player : nameEliotJ   },
      { position : "b", player : nameBrianO   },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : namePaulW    },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameMikeyC   },
    ],
  },
  {
    date    : "January 8, 2023",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2023-01-08",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songAncientGates,   singer : nameGailC  },
      { start : "4:39",  title : songMightyRedeemer, singer : nameCadenW },
      { start : "10:02", title : songLivingHope,     singer : nameEricaS },
      { start : "15:35", title : songWorthy,         singer : nameEmmaB  },
    ],
    band : [
      { position : "d", player : nameMikeyC   },
      { position : "b", player : nameMartyS   },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameJasonC   },
      { position : "k", player : nameCharlieB },
    ],
  },
  {
    date    : "January 4, 2023",
    day     : "Wednesday",
    events  : ["Echo"],
    slug    : "2023-01-04",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songThisIsAmazingGrace,     singer : nameMikeyC },
      { start : "4:23",  title : songNothingIsHoldingMeBack, singer : nameEmmaB  },
      { start : "8:19",  title : songAnotherInTheFire,       singer : nameEmmaB  },
      { start : "12:42", title : songNoLongerSlaves,         singer : nameMikeyC },
    ],
    band : [
      { position : "e", player : nameEzrieK   },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameMikeyC   },
    ],
  },
  {
    date    : "January 1, 2023",
    day     : "Sunday",
    events  : ["Main", "New Year's Day"],
    slug    : "2023-01-01",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songGodIsAble,     singer : nameHallieW },
      { start : "2:53",  title : songComeWhatMay,   singer : nameMikeyC  },
      { start : "6:32",  title : songYouCame,       singer : nameHallieW },
      { start : "13:34", title : songGoodnessOfGod, singer : nameGailC   },
    ],
    band : [
      { position : "e", player : nameEzrieK   },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameMikeyC   },
    ],
  },
  {
    date    : "December 24, 2022",
    day     : "Saturday",
    events  : ["Main", "Christmas Eve"],
    slug    : "2022-12-24",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songAncientGates,             singer : nameGailC    },
      { start : "3:54",  title : songJoyToTheWorld,            singer : nameEricaS   },
      { start : "8:19",  title : songHarkTheHerald,            singer : nameEmmaB    },
      { start : "11:51", title : songItCameUponAMidnightClear, singer : nameEnsemble },
      { start : "14:51", title : songAngelsWeHaveHeardOnHigh,  singer : nameCadenW   },
      { start : "17:04", title : songBeautifulJesus,           singer : nameEricaS   },
      { start : "18:11", title : songTellMeTheStoryOfJesus,    singer : nameEmmaB    },
      { start : "20:02", title : songComeAllYeFaithful,        singer : nameMikeyC   },
      { start : "22:13", title : songInstrumental,             singer : nameEnsemble },
      { start : "23:09", title : songOHolyNight,               singer : nameEmmaB    },
    ],
    band : [
      { position : "d", player : nameMikeyC   },
      { position : "b", player : nameMartyS   },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameCalebH   },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameJasonC   },
    ],
  },
  {
    date    : "December 18, 2022",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2022-12-18",
    remix   : false,
    setlist : [
      { start : "0:00", title : songJoyToTheWorld,    singer : nameMikeyC  },
      { start : "4:43", title : songBeautifulJesus,   singer : nameHallieW },
      { start : "9:22", title : songAnotherInTheFire, singer : nameEmmaB   },
    ],
    band : [
      { position : "d", player : nameEliotJ   },
      { position : "b", player : nameMartyS   },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameTylerC   },
      { position : "k", player : nameCharlieB },
      { position : "a", player : nameMikeyC   },
    ],
  },
  {
    date    : "December 11, 2022",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2022-12-11",
    remix   : false,
    setlist : [
      { start : "0:00", title : songGloria,       singer : nameCassieC },
      { start : "0:47", title : songAncientGates, singer : nameBeccahR },
      { start : "5:41", title : songComeWhatMay,  singer : nameMikeyC  },
      { start : "8:55", title : songSurrounded,   singer : nameEmmaB   },
    ],
    band : [
      { position : "d", player : nameJasonS   },
      { position : "b", player : nameAlexJ    },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameTylerC   },
      { position : "k", player : nameCharlieB },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameMikeyC   },
    ],
  },
  {
    date    : "December 7, 2022",
    day     : "Wednesday",
    events  : ["Echo"],
    slug    : "2022-12-07",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songGreatThings,              singer : nameCharlieB },
      { start : "4:32",  title : songSomethingGood,            singer : nameCharlieB },
      { start : "8:30",  title : songAnotherInTheFire,         singer : nameEmmyH    },
      { start : "13:15", title : songInTheHandsOfChristMyKing, singer : nameCharlieB },
    ],
    band : [
      { position : "d", player : nameMikeyC   },
      { position : "b", player : nameCalebH   },
      { position : "e", player : nameEzrieK   },
      { position : "k", player : nameShannonC },
    ],
  },
  {
    date    : "December 4, 2022",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2022-12-04",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songHarkTheHeraldAngelsSing, singer : nameGailC  },
      { start : "3:31",  title : songMightyRedeemer,          singer : nameCadenW },
      { start : "8:14",  title : songISpeakJesus,             singer : nameEricaS },
      { start : "13:21", title : songNothingElse,             singer : nameGeniB  },
    ],
    band : [
      { position : "d", player : nameMikeyC   },
      { position : "b", player : nameCalebH   },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameJasonC   },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameCadenW   },
    ],
  },
  {
    date    : "November 30, 2022",
    day     : "Wednesday",
    events  : ["Echo"],
    slug    : "2022-11-30",
    remix   : false,
    setlist : [
      { start : "0:00", title : songHeIsFaithful, singer : nameMikeyC },
      { start : "4:29", title : songComeWhatMay,  singer : nameMikeyC },
      { start : "7:46", title : songWorthy,       singer : nameEmmaB  },
    ],
    band : [
      { position : "d", player : nameJordanP  },
      { position : "b", player : nameTylerC   },
      { position : "e", player : namePaulW    },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameMikeyC   },
    ],
  },
  {
    date    : "November 20, 2022",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2022-11-20",
    remix   : false,
    setlist : [
      { start : "0:00", title : songOhHowINeedYou, singer : nameCadenW },
      { start : "2:58", title : songBreathe,       singer : nameEmmaB  },
      { start : "4:37", title : songHosanna,       singer : nameEmmaB  },
      { start : "9:23", title : songRecklessLove,  singer : nameMikeyC },
    ],
    band : [
      { position : "d", player : nameEliotJ   },
      { position : "b", player : nameMartyS   },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameCalebH   },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameMikeyC   },
    ],
  },
  {
    date    : "November 13, 2022",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2022-11-13",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songAncientGates,   singer : nameHallieW },
      { start : "6:22",  title : songBeautifulJesus, singer : nameEmmaB   },
      { start : "11:06", title : songGratitude,      singer : nameGailC   },
    ],
    band : [
      { position : "d", player : nameMikeyC   },
      { position : "b", player : nameAlexJ    },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameCalebH   },
      { position : "k", player : nameCharlieB },
    ],
  },
  {
    date    : "November 9, 2022",
    day     : "Wednesday",
    events  : ["Echo"],
    slug    : "2022-11-09",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songYouNeverLetGo, singer : nameCharlieB },
      { start : "4:56",  title : songRooftops,      singer : nameEmmyH    },
      { start : "8:56",  title : songAbide,         singer : nameCharlieB },
      { start : "14:32", title : songDrawMeClose,   singer : nameCharlieB },
    ],
    band : [
      { position : "d", player : nameMikeyC   },
      { position : "b", player : nameCalebH   },
      { position : "e", player : nameEzrieK   },
      { position : "k", player : nameShannonC },
    ],
  },
  {
    date    : "November 6, 2022",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2022-11-06",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songRaiseAHallelujah, singer : nameGailC },
      { start : "4:47",  title : songRevelationSong,   singer : nameEmmaB },
      { start : "9:25",  title : songISpeakJesus,      singer : nameGeniB },
      { start : "14:39", title : songGoodnessOfGod,    singer : nameGailC },
      { start : "17:15", title : songBeNear,           singer : nameGailC },
    ],
    band : [
      { position : "d", player : nameEliotJ   },
      { position : "b", player : nameMartyS   },
      { position : "e", player : nameEzrieK   },
      { position : "a", player : nameJasonC   },
      { position : "k", player : nameCharlieB },
    ],
  },
  {
    date    : "November 2, 2022",
    day     : "Wednesday",
    events  : ["Echo"],
    slug    : "2022-11-02",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songGreatThings, singer : nameMikeyC },
      { start : "4:22",  title : songOceans,      singer : nameEmmaB  },
      { start : "8:39",  title : songChampion,    singer : nameMikeyC },
    ],
    band : [
      { position : "d", player : nameEliotJ   },
      { position : "b", player : nameCalebH   },
      { position : "e", player : nameEzrieK   },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameMikeyC   },
    ],
  },
  {
    date    : "October 23, 2022",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2022-10-23",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songAncientGates,    singer : nameHallieW },
      { start : "4:29",  title : songComeThouFount,   singer : nameGailC   },
      { start : "8:37",  title : songISpeakJesus,     singer : nameGeniB   },
      { start : "13:47", title : songOComeToTheAltar, singer : nameMikeyC  },
    ],
    band : [
      { position : "d", player : nameEliotJ   },
      { position : "b", player : nameAlexJ    },
      { position : "e", player : nameEzrieK   },
      { position : "a", player : nameJasonC   },
      { position : "k", player : nameShannonC },
      { position : "a", player : nameMikeyC   },
    ],
  },
  {
    date    : "October 16, 2022",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2022-10-16",
    remix   : false,
    setlist : [
      { start : "0:00", title : songAwakeMySoul,           singer : nameCassieC },
      { start : "3:28", title : songComeWhatMay,           singer : nameJasonC  },
      { start : "8:18", title : songHowDeepTheFathersLove, singer : nameBeccahR },
      { start : "9:57", title : songWorthy,                singer : nameGeniB   },
    ],
    band : [
      { position : "d", player : nameMikeyC   },
      { position : "b", player : nameTylerC   },
      { position : "e", player : nameEzrieK   },
      { position : "a", player : nameJasonC   },
      { position : "k", player : nameCharlieB },
    ],
  },
  {
    date    : "October 12, 2022",
    day     : "Wednesday",
    events  : ["Echo"],
    slug    : "2022-10-12",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songHosanna,           singer : nameEmmaB  },
      { start : "4:43",  title : songAllThingsTogether, singer : nameMikeyC },
    ],
    band : [
      { position : "d", player : nameJordanP },
      { position : "b", player : nameBrianO  },
      { position : "e", player : nameEzrieK  },
      { position : "e", player : namePaulW   },
      { position : "k", player : nameBeccahR },
      { position : "a", player : nameMikeyC  },
    ],
  },
  {
    date    : "October 5, 2022",
    day     : "Wednesday",
    events  : ["Echo"],
    slug    : "2022-10-05",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songIntro,           singer : nameCharlieB },
      { start : "0:21",  title : songSalvationIsHere, singer : nameCharlieB },
      { start : "5:08",  title : songAbide,           singer : nameCharlieB },
      { start : "10:24", title : songBeautifulJesus,  singer : nameEmmaD    },
    ],
    band : [
      { position : "d", player : nameMikeyC   },
      { position : "b", player : nameCalebH   },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : namePaulW    },
      { position : "k", player : nameShannonC },
    ],
  },
  {
    date    : "October 2, 2022",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2022-10-02",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songComeAlive,             singer : nameGailC   },
      { start : "4:29",  title : songTurnYourEyesUponJesus, singer : nameCassieC },
      { start : "6:16",  title : songBeautifulJesus,        singer : nameEmmaB   },
      { start : "11:01", title : songBlessedAssurance,      singer : nameCadenW  },
      { start : "14:54", title : songQuiet,                 singer : nameCadenW  },
    ],
    band : [
      { position : "d", player : nameMikeyC   },
      { position : "b", player : nameMartyS   },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameJasonC   },
      { position : "k", player : nameShannonC },
    ],
  },
  {
    date    : "September 25, 2022",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2022-09-25",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songMyChainsAreGone,    singer : nameGailC  },
      { start : "2:06",  title : songEgypt,              singer : nameMikeyC },
      { start : "8:31",  title : songISpeakJesus,        singer : nameEricaS },
      { start : "13:28", title : songItIsWell,           singer : nameMacyW  },
      { start : "17:44", title : songWhatABeautifulName, singer : nameMikeyC },
    ],
    band : [
      { position : "d", player : nameEliotJ  },
      { position : "b", player : nameAlexJ   },
      { position : "e", player : nameEzrieK  },
      { position : "e", player : nameTylerC  },
      { position : "a", player : nameMikeyC  },
      { position : "k", player : nameBeccahR },
    ],
  },
  {
    date    : "September 21, 2022",
    day     : "Wednesday",
    events  : ["Echo"],
    slug    : "2022-09-21",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songGloriousDay,      singer : nameMikeyC   },
      { start : "4:14",  title : songSeptember,        singer : nameWhitneyC },
      { start : "4:46",  title : songComeWhatMay,      singer : nameMikeyC   },
      { start : "8:06",  title : songAnotherInTheFire, singer : nameEmmaB    },
      { start : "12:35", title : songNoLongerSlaves,   singer : nameEmmaB    },
    ],
    band : [
      { position : "d", player : nameCalebH   },
      { position : "b", player : nameBrianO   },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : namePaulW    },
      { position : "a", player : nameMikeyC   },
      { position : "k", player : nameShannonC },
    ],
  },
  {
    date    : "September 18, 2022",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2022-09-18",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songGodIsAble,            singer : nameGeniB  },
      { start : "3:59",  title : songComeWhatMay,          singer : nameMikeyC },
      { start : "7:18",  title : songRefiner,              singer : nameEmmaB  },
      { start : "12:44", title : songHisEyeIsOnTheSparrow, singer : nameGailC  },
    ],
    band : [
      { position : "d", player : nameEliotJ   },
      { position : "b", player : nameAlexJ    },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameJustinM  },
      { position : "a", player : nameMikeyC   },
      { position : "k", player : nameCharlieB },
    ],
  },
  {
    date    : "September 11, 2022",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2022-09-11",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songUnstoppableGod,   singer : nameGailC   },
      { start : "5:16",  title : songBeautifulJesus,   singer : nameHallieW },
      { start : "10:02", title : songDeathWasArrested, singer : nameMikeyC  },
      { start : "15:09", title : songGratitude,        singer : nameMikeyC  },
    ],
    band : [
      { position : "d", player : nameEliotJ   },
      { position : "b", player : nameMartyS   },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameJasonC   },
      { position : "a", player : nameMikeyC   },
      { position : "k", player : nameShannonC },
    ],
  },
  {
    date    : "September 7, 2022",
    day     : "Wednesday",
    events  : ["Echo"],
    slug    : "2022-09-07",
    remix   : false,
    setlist : [
      { start : "0:00", title : songHeIsFaithful, singer : nameMikeyC },
      { start : "2:25", title : songComeWhatMay,  singer : nameMikeyC },
      { start : "5:47", title : songYouCame,      singer : nameEmmaB  },
      { start : "9:47", title : songWayMaker,     singer : nameEmmaB  },
    ],
    band : [
      { position : "d", player : nameEliotJ   },
      { position : "b", player : nameTylerC   },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : namePaulW    },
      { position : "a", player : nameMikeyC   },
      { position : "k", player : nameShannonC },
    ],
  },
  {
    date    : "August 31, 2022",
    day     : "Wednesday",
    events  : ["Echo"],
    slug    : "2022-08-31",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songTakeItAll,                 singer : nameCharlieB },
      { start : "4:14",  title : songWhatTheWorldWillNeverTake, singer : nameCharlieB },
      { start : "8:13",  title : songMakeRoom,                  singer : nameCassieC  },
      { start : "14:58", title : songHeresMyHeart,              singer : nameCharlieB },
    ],
    band : [
      { position : "d", player : nameMikeyC   },
      { position : "b", player : nameCalebH   },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : namePaulW    },
      { position : "k", player : nameShannonC },
    ],
  },
  {
    date    : "August 28, 2022",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2022-08-28",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songSurrounded,        singer : nameEmmaB   },
      { start : "5:46",  title : songChristBeMagnified, singer : nameJasonC  },
      { start : "10:30", title : song10000Reasons,      singer : nameBeccahR },
      { start : "13:27", title : songHereAgain,         singer : nameCadenW  },
      { start : "17:46", title : songDryBones,          singer : nameEmmaB   },
    ],
    band : [
      { position : "d", player : nameMikeyC   },
      { position : "b", player : nameAlexJ    },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameJustinM  },
      { position : "a", player : nameJasonC   },
      { position : "k", player : nameShannonC },
    ],
  },
  {
    date    : "August 24, 2022",
    day     : "Wednesday",
    events  : ["Echo"],
    slug    : "2022-08-24",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songHeIsFaithful,           singer : nameMikeyC },
      { start : "4:26",  title : songEgypt,                  singer : nameMikeyC },
      { start : "8:28",  title : songNothingIsHoldingMeBack, singer : nameMikeyC },
      { start : "13:02", title : songLeadMeToTheCross,       singer : nameEmmaB  },
      { start : "17:25", title : songLordINeedYou,           singer : nameEmmaB  },
    ],
    band : [
      { position : "d", player : nameEliotJ   },
      { position : "b", player : nameCalebH   },
      { position : "e", player : nameEzrieK   },
      { position : "a", player : nameMikeyC   },
      { position : "k", player : nameCharlieB },
    ],
  },
  {
    date    : "August 21, 2022",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2022-08-21",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songYesIWill,          singer : nameMikeyC },
      { start : "2:54",  title : songYouCame,           singer : nameEmmaB  },
      { start : "6:35",  title : songChristBeMagnified, singer : nameGailC  },
      { start : "9:44",  title : songJesusPaidItAll,    singer : nameGailC  },
      { start : "11:42", title : songRefiner,           singer : nameMikeyC },
      { start : "15:57", title : songGoodnessOfGod,     singer : nameGailC  },
    ],
    band : [
      { position : "e", player : nameEzrieK   },
      { position : "a", player : nameMikeyC   },
      { position : "k", player : nameShannonC },
    ],
  },
  {
    date    : "August 20, 2022",
    day     : "Saturday",
    events  : ["Ascent Conference"],
    slug    : "2022-08-20",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songGodSoLoved,      singer : nameGeniB  },
      { start : "3:39",  title : songOceans,          singer : nameEmmaB  },
      { start : "8:21",  title : songWorldNeedsJesus, singer : nameGailC  },
      { start : "10:07", title : songYesIWill,        singer : nameMikeyC },
    ],
    band : [
      { position : "d", player : nameEliotJ   },
      { position : "b", player : nameMartyS   },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameJasonC   },
      { position : "a", player : nameMikeyC   },
      { position : "k", player : nameCharlieB },
    ],
  },
  {
    date    : "August 14, 2022",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2022-08-14",
    remix   : true,
    setlist : [
      { start : "0:00",  title : songIntro,                      singer : nameMikeyC },
      { start : "0:17",  title : songHeIsFaithful,               singer : nameMikeyC },
      { start : "4:36",  title : songOForAThousandTonguesToSing, singer : nameGailC  },
      { start : "6:23",  title : songInterlude,                  singer : nameJofeeT },
      { start : "7:38",  title : songLivingHope,                 singer : nameGeniB  },
      { start : "12:11", title : songGratitude,                  singer : nameMikeyC },
    ],
    band : [
      { position : "d", player : nameEliotJ   },
      { position : "b", player : nameAlexJ    },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameTylerC   },
      { position : "a", player : nameMikeyC   },
      { position : "k", player : nameCharlieB },
    ],
  },
  {
    date    : "August 7, 2022",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2022-08-07",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songSetAFire,          singer : nameGailC },
      { start : "2:07",  title : songChristBeMagnified, singer : nameEmmaD },
      { start : "6:24",  title : songAnotherInTheFire,  singer : nameEmmaB },
      { start : "10:49", title : songBlessedAssurance,  singer : nameGailC },
    ],
    band : [
      { position : "d", player : nameMikeyC  },
      { position : "b", player : nameTylerC  },
      { position : "e", player : nameEzrieK  },
      { position : "e", player : nameJasonC  },
      { position : "k", player : nameBeccahR },
    ],
  },
  {
    date    : "July 31, 2022",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2022-07-31",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songGreatThings,        singer : nameCassieC },
      { start : "4:29",  title : songMakeRoom,           singer : nameCassieC },
      { start : "9:39",  title : songNoOtherName,        singer : nameCadenW  },
      { start : "17:31", title : songWorthyOfYourName,   singer : nameMacyW   },
      { start : "18:52", title : songWhatABeautifulName, singer : nameMacyW   },
    ],
    band : [
      { position : "d", player : nameEliotJ   },
      { position : "b", player : nameMartyS   },
      { position : "e", player : nameEzrieK   },
      { position : "a", player : nameCadenW   },
      { position : "k", player : nameCharlieB },
    ],
  },
  {
    date    : "July 24, 2022",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2022-07-24",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songHouseOfTheLord, singer : nameCadenW   },
      { start : "4:01",  title : songTheWay,         singer : nameGeniB    },
      { start : "8:08",  title : songThisIBelieve,   singer : nameGeniB    },
      { start : "9:38",  title : songJesusOnlyJesus, singer : nameCharlieB },
      { start : "13:11", title : songThisIBelieve,   singer : nameCharlieB },
    ],
    band : [
      { position : "d", player : nameJasonS   },
      { position : "b", player : nameAlexJ    },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameTylerC   },
      { position : "a", player : nameHallieW  },
      { position : "k", player : nameShannonC },
    ],
  },
  {
    date    : "July 17, 2022",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2022-07-17",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songAwakeMySoul,          singer : nameGeniB  },
      { start : "3:10",  title : songEgypt,                singer : nameMikeyC },
      { start : "7:01",  title : songComeThouFount,        singer : nameEricaS },
      { start : "8:43",  title : songAllThingsTogether,    singer : nameMikeyC },
      { start : "13:37", title : songAThousandHallelujahs, singer : nameMacyW  },
    ],
    band : [
      { position : "d", player : nameJasonS   },
      { position : "b", player : nameAlexJ    },
      { position : "e", player : nameJasonC   },
      { position : "e", player : nameEzrieK   },
      { position : "a", player : nameMikeyC   },
      { position : "k", player : nameShannonC },
    ],
  },
  {
    date    : "July 12, 2022",
    day     : "Tuesday",
    events  : ["Stay Camp, Day 2"],
    slug    : "2022-07-12",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songGreatThings, singer : nameMikeyC },
      { start : "4:14",  title : songMonologue,   singer : nameMikeyC },
      { start : "6:49",  title : songEgypt,       singer : nameMikeyC },
      { start : "10:41", title : songWayMaker,    singer : nameEricaS },
      { start : "14:25", title : songBuildMyLife, singer : nameMikeyC },
    ],
    band : [
      { position : "d", player : nameEliotJ   },
      { position : "b", player : nameBrianO   },
      { position : "e", player : namePaulW    },
      { position : "e", player : nameEzrieK   },
      { position : "a", player : nameMikeyC   },
      { position : "k", player : nameShannonC },
    ],
  },
  {
    date    : "July 11, 2022",
    day     : "Monday",
    events  : ["Stay Camp, Day 1"],
    slug    : "2022-07-11",
    remix   : false,
    setlist : [
      { start : "0:00", title : songGloriousDay,    singer : nameMikeyC },
      { start : "4:25", title : songOceans,         singer : nameEmmaB  },
      { start : "9:06", title : songNoLongerSlaves, singer : nameEricaS },
    ],
    band : [
      { position : "d", player : nameEliotJ   },
      { position : "b", player : nameBrianO   },
      { position : "e", player : namePaulW    },
      { position : "e", player : nameEzrieK   },
      { position : "a", player : nameMikeyC   },
      { position : "k", player : nameShannonC },
    ],
  },
  {
    date    : "July 10, 2022",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2022-07-10",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songComeAlive,        singer : nameGeniB  },
      { start : "4:41",  title : songYouCame,          singer : nameEmmaB  },
      { start : "8:42",  title : songBlessedAssurance, singer : nameGailC  },
      { start : "12:22", title : songGratitude,        singer : nameCadenW },
    ],
    band : [
      { position : "d", player : nameMikeyC   },
      { position : "b", player : nameAlexJ    },
      { position : "e", player : namePaulW    },
      { position : "e", player : nameJasonC   },
      { position : "a", player : nameCadenW   },
      { position : "k", player : nameCharlieB },
    ],
  },
  {
    date    : "June 26, 2022",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2022-06-26",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songYesIWill,         singer : nameCadenW   },
      { start : "3:34",  title : songWorthyOfYourName, singer : nameCadenW   },
      { start : "6:41",  title : songMyKingForever,    singer : nameCharlieB },
      { start : "11:58", title : songMoreThanAnything, singer : nameGeniB    },
    ],
    band : [
      { position : "d", player : nameEliotJ   },
      { position : "b", player : nameTylerC   },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameJasonC   },
      { position : "a", player : nameJustinM  },
      { position : "k", player : nameShannonC },
    ],
  },
  {
    date    : "June 19, 2022",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2022-06-19",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songIntro,            singer : nameBand   },
      { start : "1:46",  title : songPraiseYourName,   singer : nameGeniB  },
      { start : "6:09",  title : songTheWay,           singer : nameMikeyC },
      { start : "10:11", title : songMakeRoom,         singer : nameEricaS },
      { start : "14:51", title : songHereIAmToWorship, singer : nameMikeyC },
    ],
    band : [
      { position : "d", player : nameEliotJ   },
      { position : "b", player : nameMartyS   },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameJasonC   },
      { position : "a", player : nameMikeyC   },
      { position : "k", player : nameCharlieB },
    ],
  },
  {
    date    : "June 12, 2022",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2022-06-12",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songIntro,            singer : nameBand   },
      { start : "1:21",  title : songGiveMeFaith,      singer : nameCadenW },
      { start : "5:29",  title : songDesertSong,       singer : nameEmmaB  },
      { start : "10:03", title : songMyChainsAreGone,  singer : nameMacyW  },
      { start : "11:13", title : songFromTheInsideOut, singer : nameGeniB  },
    ],
    band : [
      { position : "d", player : nameMikeyC   },
      { position : "b", player : nameMartyS   },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameNickC    },
      { position : "a", player : nameCadenW   },
      { position : "k", player : nameShannonC },
    ],
  },
  {
    date    : "June 5, 2022",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2022-06-05",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songIntro,            singer : nameBand   },
      { start : "2:13",  title : songBattleBelongs,    singer : nameCadenW },
      { start : "6:47",  title : songRooftops,         singer : nameGailC  },
      { start : "10:59", title : songAnotherInTheFire, singer : nameEmmaB  },
      { start : "15:47", title : songSurrounded,       singer : nameEmmaB  },
    ],
    band : [
      { position : "d", player : nameMikeyC   },
      { position : "b", player : nameMartyS   },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameJasonC   },
      { position : "k", player : nameShannonC },
    ],
  },
  {
    date    : "June 1, 2022",
    day     : "Wednesday",
    events  : ["Echo"],
    slug    : "2022-06-01",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songAlive,             singer : nameEricaS   },
      { start : "4:00",  title : songNeverLost,         singer : nameCadenW   },
      { start : "8:26",  title : songChristBeMagnified, singer : nameEmmaD    },
      { start : "13:34", title : songGratitude,         singer : nameCadenW   },
      { start : "20:02", title : songTouchOfHeaven,     singer : nameCharlieB },
    ],
    band : [
      { position : "d", player : nameMikeyC   },
      { position : "b", player : nameCalebH   },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : namePaulW    },
      { position : "a", player : nameCadenW   },
      { position : "k", player : nameShannonC },
    ],
  },
  {
    date    : "May 29, 2022",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2022-05-29",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songEverlastingGod,   singer : nameCassieC  },
      { start : "3:55",  title : songWhoIsLikeTheLord, singer : nameGeniB    },
      { start : "9:21",  title : songNoOtherName,      singer : nameCharlieB },
    ],
    band : [
      { position : "d", player : nameJasonS   },
      { position : "b", player : nameAlexJ    },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameJasonC   },
      { position : "k", player : nameShannonC },
    ],
  },
  {
    date    : "May 22, 2022",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2022-05-22",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songIntro,           singer : nameEricaS },
      { start : "0:21",  title : songComeAlive,       singer : nameEricaS },
      { start : "4:58",  title : songSameGod,         singer : nameGailC  },
      { start : "10:20", title : songItIsWell,        singer : nameEmmaB  },
      { start : "12:54", title : songOComeToTheAltar, singer : nameCadenW },
    ],
    band : [
      { position : "d", player : nameMikeyC   },
      { position : "b", player : nameAlexJ    },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : namePaulW    },
      { position : "a", player : nameCadenW   },
      { position : "k", player : nameShannonC },
    ],
  },
  {
    date    : "May 18, 2022",
    day     : "Wednesday",
    events  : ["Echo"],
    slug    : "2022-05-18",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songGloriousDay,    singer : nameCadenW },
      { start : "4:27",  title : songFaithAndWonder, singer : nameEmmaD  },
      { start : "10:16", title : songBuildMyLife,    singer : nameMikeyC },
    ],
    band : [
      { position : "d", player : nameEliotJ },
      { position : "b", player : nameBrianO },
      { position : "e", player : namePaulW  },
      { position : "a", player : nameMikeyC },
      { position : "k", player : nameEzrieK },
    ],
  },
  {
    date    : "May 15, 2022",
    day     : "Sunday",
    events  : ["Main"],
    slug    : "2022-05-15",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songIntro,            singer : nameCadenW },
      { start : "0:17",  title : songUnstoppableGod,   singer : nameCadenW },
      { start : "5:08",  title : songWorthyOfYourName, singer : nameGailC  },
      { start : "9:52",  title : songLeadMeToTheCross, singer : nameEmmaB  },
      { start : "14:12", title : songLordINeedYou,     singer : nameEmmaB  },
      { start : "15:17", title : songMoreLikeJesus,    singer : nameMikeyC },
    ],
    band : [
      { position : "d", player : nameJasonS   },
      { position : "b", player : nameAlexJ    },
      { position : "e", player : nameEzrieK   },
      { position : "e", player : nameJasonC   },
      { position : "a", player : nameMikeyC   },
      { position : "k", player : nameCharlieB },
    ],
  },
  {
    date    : "May 8, 2022",
    day     : "Sunday",
    events  : ["Main", "Mother's Day"],
    slug    : "2022-05-08",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songIntro,          singer : nameBand    },
      { start : "2:00",  title : songHouseOfTheLord, singer : nameCassieC },
      { start : "6:08",  title : songDoItAgain,      singer : nameEricaS  },
      { start : "11:21", title : songGoodnessOfGod,  singer : nameGeniB   },
      { start : "15:29", title : songOPraiseTheName, singer : nameMacyW   },
    ],
    band : [
      { position : "d", player : nameEliotJ   },
      { position : "b", player : nameMartyS   },
      { position : "e", player : namePaulW    },
      { position : "a", player : nameMikeyC   },
      { position : "k", player : nameCharlieB },
    ],
  },
  {
    date    : "May 1, 2022",
    day     : "Sunday",
    events  : ["Main", "Baptism"],
    slug    : "2022-05-01",
    remix   : false,
    setlist : [
      { start : "0:00",  title : songIntro,           singer : nameCountdown },
      { start : "2:02",  title : songOhHowINeedYou,   singer : nameCadenW    },
      { start : "6:08",  title : songGodIsAble,       singer : nameMikeyC    },
      { start : "8:57",  title : songGreatAreYouLord, singer : nameEmmaB     },
      { start : "12:58", title : songCornerstone,     singer : nameMikeyC    },
      { start : "17:15", title : songRiver,           singer : nameMikeyC    },
    ],
    band : [
      { position : "a", player : nameMikeyC },
    ],
  },
]
